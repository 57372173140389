import { Stack, useMediaQuery, useTheme, Button, Typography } from '@mui/material';
import BeneficiaryIcon from '../../Assets/user_1.svg';
import ConsultantIcon from '../../Assets/user_2.svg';
import OrganismIcon from '../../Assets/user_3.svg';

export interface LoginCardProps {
  type: 'consultant' | 'organism' | 'beneficiary';
  handleClick: (type: string) => void;
}

export interface IndicatorProps {
  type: string;
}

export const TopColorIndicator = (props: IndicatorProps) => {
  const { type } = props;
  
  const getColor = (alpha: '0.1' | '1') => {
    switch (type) {
      case 'organism':
        return `rgba(239, 133, 90, ${alpha})`;
      case 'beneficiary':
        return `rgba(108, 40, 105, ${alpha})`;
      case 'consultant':
        return `rgba(90, 173, 177, ${alpha})`;
    }
  };

  return (
    <Stack sx={{
      backgroundColor: getColor('1'),
      top: '0px',
      left: '0px',
      right: '0px',
      height: '1rem',
      position: 'absolute',
      borderRadius: '15px 15px 0px 0px'
    }}></Stack>
  )
}

export const LoginCard = (props: LoginCardProps) => {
  const { type, handleClick } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const getColor = (alpha: '0.1' | '1') => {
    switch (type) {
      case 'organism':
        return `rgba(239, 133, 90, ${alpha})`;
      case 'beneficiary':
        return `rgba(108, 40, 105, ${alpha})`;
      default:
        return `rgba(90, 173, 177, ${alpha})`;
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'organism':
        return <img src={OrganismIcon} alt='' />;
      case 'beneficiary':
        return <img src={BeneficiaryIcon} alt='' />
      default:
        return <img src={ConsultantIcon} alt='' />
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'organism':
        return <>Espace<br/>organisme</>;
      case 'beneficiary':
        return <>Espace<br/>bénéficiaire</>;
      default:
        return <>Espace<br/>consultant</>;
    }
  }

  const getDescription = () => {
    switch (type) {
      case 'organism':
        return (
          <Typography fontSize="14" fontWeight="400" color="rgba(113, 113, 113, 1)">
            Vous êtes un <span style={{fontWeight: 800}}>Organisme</span> et vous<br/>
            souhaitez vous connecter sur<br/>
            votre espace personnel
          </Typography>
                );
      case 'beneficiary':
        return (
          <Typography fontSize="14" fontWeight="400" color="rgba(113, 113, 113, 1)">
            Vous êtes <span style={{fontWeight: 800}}>Bénéficiaire</span> et<br/>
            vous souhaitez vous connecter sur<br/>
            votre espace personnel
          </Typography>
        );
      default:
        return (
          <Typography fontSize="14" fontWeight="400" color="rgba(113, 113, 113, 1)">
            Vous êtes un <span style={{fontWeight: 800}}>Consultant</span> et<br/>
            vous souhaitez vous connecter<br/>
            sur votre espace personnel
          </Typography>
        );
    }
  }

  return (
    <Button
      sx={{
        position: 'relative',
        width: '100%',
        boxShadow: theme.customShadows.shadow2,
        padding: isSm ? theme.spacing(2) : theme.spacing(3),
        zIndex: 3,
        ":hover": {
          cursor: 'pointer',
        },
      }}
      onClick={() => handleClick(type)}
    >
    <Stack 
      direction="column" 
      alignItems="center" 
      justifyContent="center" 
      spacing={{xs: 2, sm: 3}} 
      padding={{sm: 0, md: 1}}
      minWidth='300px'>
        <Stack sx={{
          backgroundColor: getColor('1'),
          top: '0px',
          left: '0px',
          right: '0px',
          height: '1rem',
          position: 'absolute',
          borderRadius: '15px 15px 0px 0px'
        }}></Stack>
        <Stack  
          alignItems='center'
          justifyContent='center'
          sx={{
            borderRadius: '30px',
            padding: '1 rem',
          }}>
          {getIcon()}
        </Stack>
        <Typography fontSize={18} fontWeight={800} color="rgba(43, 15, 54, 1)">
          {getTitle()}
        </Typography>
        <>
          {getDescription()}
        </>
      </Stack>
    </Button>
  );
}