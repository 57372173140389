import { useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useUserProfile, usePopup } from "../../../../../Hooks";
import { BeneficiaryProfileContext } from "../../../../../Providers";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { ActionStatus, ActionType } from "../../../../../Types/actions.type";

export const ActionRead = () => {
  const { selectedAction } = useUserProfile(BeneficiaryProfileContext);

  return (
    <Grid container>
      <Typography
        variant="h4"
        style={{ marginRight: "auto", marginBottom: "1rem" }}
      >
        Description
      </Typography>
      <TextField
        inputProps={{
          readOnly: true,
        }}
        focused
        fullWidth
        multiline
        minRows={8}
        color="primary"
        placeholder="Description"
        defaultValue={selectedAction?.description || ""}
      />
    </Grid>
  );
};

export const ActionForm = () => {
  const { popupMode } = usePopup("ActionPopup");
  const { selectedAction, user } = useUserProfile(BeneficiaryProfileContext);
  const { register, formState, control } = useFormContext();

  useMemo(() => {
    return popupMode() === "edit"
      ? new Date(selectedAction?.updatedAt || "")
      : new Date();
  }, [popupMode, selectedAction?.updatedAt]);

  return (
    <Grid container justifyContent="space-between" gap={3}>
      <Grid item container xs={12} md={4} gap={3} direction="column">
        <Grid item container>
          <Typography variant="h4" marginBottom={2}>
            Type d&apos;action
          </Typography>
          <Controller
            name="type"
            defaultValue={selectedAction?.type || ""}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!formState.errors.type}
                helperText={
                  formState.errors.type ? formState.errors.type.message : ""
                }
                select
                type="text"
                color="primary"
                placeholder="Type d'action"
                disabled={user?.isDeleted}
              >
                {ActionType.actionTypes(
                  !!user?.beneficiary?.isBOE,
                  !!user?.beneficiary?.isWorkArrest
                ).map((key, index) => (
                  <MenuItem value={key} key={index}>
                    {ActionType.label(key)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item container>
          <Typography variant="h4" marginBottom={2}>
            Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <Controller
              control={control}
              name="createdAt"
              defaultValue={
                selectedAction?.createdAt
                  ? new Date(selectedAction.createdAt)
                  : new Date()
              }
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        error={!!formState.errors.createdDate}
                        helperText={
                          formState.errors.createdDate
                            ? (formState.errors.createdDate as any).message
                            : ""
                        }
                        fullWidth
                        placeholder="Date"
                        disabled={user?.isDeleted}
                      />
                    );
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container>
          <Typography variant="h4" marginBottom={2}>
            Statut
          </Typography>
          <Controller
            name="status"
            defaultValue={selectedAction?.status || ""}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={!!formState.errors.status}
                helperText={
                  formState.errors.status ? formState.errors.status.message : ""
                }
                select
                type="text"
                color="primary"
                placeholder="Type d'action"
                disabled={user?.isDeleted}
              >
                {ActionStatus.actionStatuses.map((status) => (
                  <MenuItem value={status} key={status}>
                    {ActionStatus.label(status)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={7} direction="column">
        <Grid item xs={12} container direction="column" gap={1}>
          <Typography variant="h4">Description</Typography>
          <TextField
            {...register("description")}
            error={!!formState.errors.description}
            helperText={
              formState.errors.description
                ? formState.errors.description.message
                : ""
            }
            fullWidth
            multiline
            minRows={8}
            required
            color="primary"
            placeholder="Description"
            disabled={user?.isDeleted}
            defaultValue={selectedAction?.description || ""}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
