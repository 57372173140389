import { Fragment } from "react";
import { FactSheet } from "../../../Types/fact-sheets.types";
import { FieldErrors } from "../../../Utils/yup.utils";
import { FirstStepFactSheet } from "./Steps/first-step-fact-sheet.component";
import { SecondStepFactSheet } from "./Steps/second-step-fact-sheet.component";
import { Button, Stack, Step, StepLabel, Stepper } from "@mui/material";
import {
  factSheetFirstStepValidation,
  factSheetSecondStepValidation,
} from "./fact-sheet-creation.validation";

interface IFactSheetStepperProps {
  updateFactSheet: (
    name: string
  ) => (value: string | number | string[] | boolean) => void;
  stepNumber: number;
  setStepNumber: (stepNumber: number) => void;
  errors: FieldErrors;
  setErrors: (errors: FieldErrors) => void;
  factSheet?: FactSheet;
  factSheetId?: string;
  onSubmit: () => void;
  isSubmitting: boolean;
}

interface IStepProps {
  factSheet?: FactSheet;
  errors: FieldErrors;
  setErrors: (errors: FieldErrors) => void;
  updateFactSheet: (
    name: string
  ) => (value: string | number | string[] | boolean) => void;
}

const steps = [
  {
    title: "Informations générales",
    component: (props: IStepProps) => <FirstStepFactSheet {...props} />,
    validation: (props: IStepProps) =>
      factSheetFirstStepValidation(props.factSheet as FactSheet),
  },
  {
    title: "Conditions",
    component: (props: IStepProps) => <SecondStepFactSheet {...props} />,
    validation: (props: IStepProps) =>
      factSheetSecondStepValidation(props.factSheet as FactSheet),
  },
];

export const FactSheetStepper = ({
  stepNumber,
  setStepNumber,
  onSubmit,
  setErrors,
  errors = {},
  factSheet,
  factSheetId,
  updateFactSheet,
  isSubmitting,
}: IFactSheetStepperProps) => {
  const isLastStep = stepNumber === steps.length - 1;

  const handleStepClick = (index: number) => {
    if (index < stepNumber) {
      setStepNumber(index);
    }
  };

  const handleValidationSubmit = () => {
    const validation = steps[stepNumber].validation({
      factSheet,
      errors,
      setErrors,
      updateFactSheet,
    });

    if (Object.keys(validation).length) {
      setErrors(validation);
      return;
    }

    if (isLastStep) {
      onSubmit();
      return;
    }

    setStepNumber((stepNumber += 1));
  };

  return (
    <Fragment>
      <Stepper
        activeStep={stepNumber}
        alternativeLabel
        style={{ width: "100%" }}
      >
        {steps.map(({ title }, index) => (
          <Step key={title}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              style={{ cursor: index > stepNumber ? "" : "pointer" }}
            >
              {title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[stepNumber] &&
        steps[stepNumber].component({
          factSheet,
          errors,
          setErrors,
          updateFactSheet,
        })}

      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={4}
      >
        <Button
          disabled={stepNumber === 0 || isSubmitting}
          variant="contained"
          onClick={() => setStepNumber((stepNumber -= 1))}
        >
          Précédent
        </Button>

        <Button
          disabled={isSubmitting}
          variant="contained"
          onClick={() => handleValidationSubmit()}
        >
          {isLastStep ? `${factSheetId ? "Modifier" : "Créer"} la fiche` : "Suivant"}
        </Button>
      </Stack>
    </Fragment>
  );
};
