import { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/AddRounded";
import { CircularProgress } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { differenceInDays } from "date-fns";

import { GenericCard, ProgressSpinner } from "../../../Components";
import { TDashboardProps } from "../dashboard.component";
import { usePopup } from "../../../Hooks";
import APIAxios, { APIRoutes } from "../../../API/api.axios";
import { BothBooleanEnum, FactSheet } from "../../../Types/fact-sheets.types";

//TODO set in own module

export interface UserActionsCountProps {
  count: number;
}
const UserActionsCount = (props: UserActionsCountProps) => {
  const { count } = props;

  return (
    <Typography
      component="div"
      fontSize={(theme) => theme.customShapes.fixedSize48px}
      fontWeight="bold"
    >
      {count || 0}
    </Typography>
  );
};

export const ActionsDashboard = ({
  userRole,
  consultDashboard,
  benefDashboard,
}: TDashboardProps) => {
  const { openPopup } = usePopup("CreateInvitationPopup");
  const navigate = useNavigate();
  const {
    state: { user },
  } = useStateMachine();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingFactSheets, setIsLoadingFactSheets] =
    useState<boolean>(false);
  const [relatedFactSheets, setRelatedFactSheets] = useState<FactSheet[]>([]);

  const getRelatedFactSheets = async () => {
    setIsLoadingFactSheets(true);

    try {
      // const actionsRes = await APIAxios({
      //   ...APIRoutes.GETActions(1),
      //   params: {
      //     beneficiaryId: user.beneficiary?.id,
      //   },
      // });

      // const actionTypes = actionsRes.data.data.map(
      //   (action: GetActionDto) => action.type
      // );

      const isWorkArrest = user.beneficiary?.isWorkArrest;
      const daysCount = isWorkArrest ? differenceInDays(
        new Date(),
        user.beneficiary?.dateWorkArrest
          ? new Date(user.beneficiary.dateWorkArrest)
          : new Date()
      ) : 0;

      const res = await APIAxios({
        ...APIRoutes.GETFactSheets(),
        params: {
          // actionTypes,
          isWorkArrest: [Number(user?.beneficiary?.isWorkArrest), BothBooleanEnum.both],
          isBOE: [Number(user.beneficiary?.isBOE), BothBooleanEnum.both],
          daysCount,
        },
      });

      setRelatedFactSheets(res.data);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des fiches d'information",
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoadingFactSheets(false);
    }
  };

  const renderRelatedFactSheets = () => {
    if (relatedFactSheets.length <= 0) {
      return (
        <Typography variant="body1">
          Aucune fiche d&apos;information n'est liée à votre situation
        </Typography>
      );
    }

    return (
      <Grid
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {relatedFactSheets.map((factSheet) => (
          <Button
            key={factSheet.id}
            variant="contained"
            color="secondary"
            title={factSheet.title}
            onClick={() => navigate(`/fact-sheets/${factSheet.id}`)}
            style={{ gap: 6 }}
          >
            <Typography
              color="white"
              fontWeight="bold"
            >
              {factSheet.title}
            </Typography>
          </Button>
        ))}
      </Grid>
    );
  };

  const handleBeneficiaryBlockTitle = () => {
    const isWorkArrest = user.beneficiary?.isWorkArrest;
    const daysSinceWorkArrest = differenceInDays(
      new Date(),
      user.beneficiary?.dateWorkArrest
        ? new Date(user.beneficiary.dateWorkArrest)
        : new Date()
    );

    let workArrestQuote = ""

    if (!isWorkArrest) {
      workArrestQuote = "Vous n'êtes pas en arrêt de travail"
    } else {
      workArrestQuote = `Vous êtes en arrêt de travail depuis ${daysSinceWorkArrest} jour(s)`
    }

    if (user?.beneficiary?.isBOE) {
      workArrestQuote += " et vous êtes bénéficiaire de l'obligation d'emploi."
    } else {
      workArrestQuote += " et vous n'êtes pas bénéficiaire de l'obligation d'emploi."
    }

    return (
      <Typography variant="body1">
        {workArrestQuote}
      </Typography>
    );
  };

  useEffect(() => {
    if (userRole === "beneficiary") getRelatedFactSheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return (
    <Fragment>
      <Grid container justifyContent="space-between" gap={2}>
        <Grid item xs={12} md={5.8} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="secondary">
            {userRole === "beneficiary" && (
              <Grid
                container
                direction="column"
                justifyItems="space-between"
                gap={3}
              >
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  {benefDashboard?.formCompletionPercentage !== null ? (
                    <ProgressSpinner
                      value={benefDashboard?.formCompletionPercentage || 0}
                    />
                  ) : (
                    <div>...loading</div>
                  )}
                  <Typography variant="body1">
                    {benefDashboard?.formCompletionPercentage === 100
                      ? "Votre profil est complet !"
                      : "Complété sur votre fiche bénéficiaire."}
                  </Typography>
                </Grid>
                <Button
                  variant="light"
                  onClick={() => navigate("/beneficiary/my-informations")}
                >
                  {benefDashboard?.formCompletionPercentage === 100
                    ? "Voir mes informations"
                    : "Compléter mes informations"}
                </Button>
              </Grid>
            )}

            {userRole === "consultant" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap={3}
              >
                <Add
                  sx={{
                    fontSize: (theme) => theme.customShapes.fixedSize48px,
                  }}
                />
                <Button
                  variant="light"
                  onClick={() => openPopup()}
                  disabled={!user.consultant?.isPremium}
                >
                  Inviter un(e) bénéficiaire
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={5.8} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="primary">
            {userRole === "beneficiary" && (
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  {/* <ActionsProvider uid={id as string} userRole="beneficiary"> */}
                  <UserActionsCount count={benefDashboard?.actionCount || 0} />
                  {/* </ActionsProvider> */}
                  <Typography variant="body1">
                    Démarches dans votre
                    <br />
                    parcours de maintien
                    <br />
                    dans l&apos;emploi
                  </Typography>
                </Grid>
                <Button
                  variant="light"
                  onClick={() => navigate("/beneficiary/journey")}
                >
                  Visualiser mon parcours
                </Button>
              </Grid>
            )}

            {userRole === "consultant" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  <UserActionsCount
                    count={consultDashboard?.actionCount || 0}
                  />
                  <Typography variant="body1">
                    Actions effectuées avec
                    <br />
                    vos bénéficiaires
                  </Typography>
                </Grid>
                <Button
                  variant="light"
                  onClick={() => navigate("../my-actions")}
                >
                  Voir mes actions
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>
        <Grid item xs={12} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="tertiary">
            {userRole === "beneficiary" && (
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  <Typography
                    component="div"
                    fontSize={(theme) => theme.customShapes.fixedSize48px}
                    fontWeight="bold"
                  >
                    {benefDashboard?.followingConsultantsCount}
                  </Typography>
                  <Typography variant="body1">
                    Consultant(s) connecté(s)
                    <br />à votre parcours
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => openPopup()}>
                  Partager mon parcours
                </Button>
              </Grid>
            )}

            {userRole === "consultant" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  <Typography
                    component="div"
                    fontSize={(theme) => theme.customShapes.fixedSize48px}
                    fontWeight="bold"
                  >
                    {consultDashboard?.followedBeneficiariesCount}
                  </Typography>
                  <Typography variant="body1">
                    Bénéficiaires dont vous
                    <br />
                    suivez le parcours
                  </Typography>
                </Grid>
                <Button
                  variant="light"
                  onClick={() => navigate("../my-beneficiaries/all")}
                >
                  Voir mes bénéficiaires
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>

        {userRole === "beneficiary" && (
          <Grid item xs={12} lg={7.9} container>
            <GenericCard variant="generic" color="quaternary">
              <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                gap={1}
              >
                <Typography variant="body1">
                  {handleBeneficiaryBlockTitle()}
                </Typography>

                <Typography variant="body1">
                  Découvrez les actions et dispositifs mobilisables pour votre
                  situation actuelle:
                </Typography>

                <Grid
                  item
                  container
                  direction="row"
                  flexWrap="wrap"
                  wrap="nowrap"
                  width="100%"
                  gap={1}
                >
                  {isLoadingFactSheets ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <>{renderRelatedFactSheets()}</>
                  )}
                </Grid>

                <Typography variant="body1">
                  Pour aller plus loin, nous vous conseillons d’en discuter avec
                  les consultants et structures d’accompagnement concernés.
                </Typography>

                <Typography variant="body2" fontStyle="italic">
                  “Votre assistant Maintien en emploi Sam.i”
                </Typography>

                <Button
                  variant="light"
                  onClick={() =>
                    navigate("/beneficiary/my-informations", { replace: true })
                  }
                >
                  Modifier mes informations
                </Button>
              </Grid>
            </GenericCard>
          </Grid>
        )}

        {userRole === "consultant" || userRole === "admin" ? (
          <Grid xs={12} lg={3.8} item container>
            <GenericCard variant="generic" color="quaternary">
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  gap={2}
                >
                  <Typography
                    component="div"
                    fontSize={(theme) => theme.customShapes.fixedSize48px}
                    fontWeight="bold"
                  >
                    {consultDashboard?.pendingBeneficiaryInvitationCount || 0}
                  </Typography>
                  <Typography variant="body1">
                    Invitation(s) en attente
                    <br />
                    de validation
                  </Typography>
                </Grid>
                <Button
                  variant="light"
                  onClick={() => navigate("../my-invitations")}
                >
                  Voir mes invitations
                </Button>
              </Grid>
            </GenericCard>
          </Grid>
        ) : null}
      </Grid>
    </Fragment>
  );
};
