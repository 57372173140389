/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  updateDoc,
  UpdateData,
  Unsubscribe,
  onSnapshot,
  CollectionReference,
  collection,
  query,
} from 'firebase/firestore';
import { DatabaseCollection } from '../../Configuration/firebase.config';
import { IAction, TInvitation, TUserRole } from '../../Types';
/**
 * @class An abstract class to use when defining a new service that will extend this
 * for a resource T
 */
export default abstract class AbstractProfileService<T> {
  /* The firestore instance */
  protected _db: Firestore;
  /* the collection you are dealing with */
  protected _dbCollection: DatabaseCollection;
  /* constructor */
  constructor(db: Firestore, dbCollection: DatabaseCollection) {
    this._db = db;
    this._dbCollection = dbCollection;
  }
  /**
   * Creates a new document in the database.
   * Must be implemented in sub-classes.
   * @param uid
   * @param createData (optional)
   */
  abstract create(uid: string, createData?: any): Promise<void>;
  /**
   * Calls the callback when an action is received
   * @param uid
   * @param callback
   */
  public onActionsSnapshot(uid: string, userRole: TUserRole, callback: (data: IAction[]) => void): Unsubscribe {
    const q = query(this.getActionCollectionRef(uid, userRole));
    return onSnapshot(
      q,
      (querySnapshot) =>
        callback(
          querySnapshot.docs.map((actionSnapshot) => ({ ...actionSnapshot.data(), actionId: actionSnapshot.id })),
        ),
      (error) => {
        throw error;
      },
    );
  }
  /**
   * Clear sensible user informations from database and
   * tag the account profile as deleted
   */
  public delete(uid: string, userRole: TUserRole): Promise<void> {
    try {
      return userRole === 'consultant'
        ? this.update(uid, {
            gender: '',
            birthdate: '',
            city: '',
            isDeleted: true,
            phone: '',
            postcode: '',
            street: '',
          } as any)
        : this.update(uid, {
            gender: '',
            city: '',
            isDeleted: true,
            phone: '',
            postcode: '',
            street: '',
          } as any);
    } catch (error) {
      throw error;
    }
  };
  /**
   * A utility function to return the document reference
   * @param uid
   * @returns DocumentReference<T>
   */
  protected getDocRef(uid: string): DocumentReference<T> {
    return doc(this._db, this._dbCollection, uid) as DocumentReference<T>;
  }
  /**
   * A utility function to return the user actions collection reference
   * @param uid
   * @returns CollectionReference<IAction>
   */
  protected getActionCollectionRef(uid: string, userRole: TUserRole): CollectionReference<IAction> {
    return collection(this._db, `${userRole}/${uid}/action`) as CollectionReference<IAction>;
  }
  /**
   * A utility function to return the user invitations collection reference
   * @param uid
   * @returns CollectionReference<IAction>
   */
  protected getInvitationsCollectionRef(uid: string, userRole: TUserRole): CollectionReference<TInvitation> {
    return collection(this._db, `${userRole}/${uid}/invitations`) as CollectionReference<TInvitation>;
  }
  /**
   * Calls callback when invitation is received
   * @param uid
   * @param userRole
   * @param callback
   * @returns
   */
  public onInvitationSnapshot(uid: string, userRole: TUserRole, callback: (data: TInvitation[]) => void): Unsubscribe {
    const q = query(this.getInvitationsCollectionRef(uid, userRole));
    return onSnapshot(
      q,
      (querySnapshot) =>
        callback(
          querySnapshot.docs.map((invitationSnapshot) => ({
            ...invitationSnapshot.data(),
            invitationId: invitationSnapshot.id,
          })),
        ),
      (error) => {
        throw error;
      },
    );
  }
  /**
   * Clean null | undefined values in data by setting them as empty string
   * @param data
   */
  private cleanData(data: UpdateData<T>): void {
    for (const key in data) {
      if (data[key as never] === null || data[key as never] === undefined) {
        (data[key as never] as string) = '';
      } else if (typeof data[key as never] === 'string') {
        (data[key as never] as string) = (data[key as never] as string).toLowerCase();
      }
    }
  }
  /**
   * Updates a document in the database.
   * Set null | undefined values to '' by calling cleanData()
   *
   * @param uid
   * @param updateData
   */
  public async update(uid: string, updateData: UpdateData<T>): Promise<void> {
    try {
      this.cleanData(updateData);
      return await updateDoc<T>(this.getDocRef(uid), updateData);
    } catch (error) {
      console.error(`Error updating data for collection ${this._dbCollection} uid ${uid}`, error);
    }
  }
  /**
   * Get a document in the database
   * @param uid
   * @returns
   */
  public async read(uid: string): Promise<void | T> {
    return getDoc(this.getDocRef(uid))
      .then((document) => document.data() as T)
      .catch((error) => console.error('Error reading data', error));
  }
  /**
   * Calls the onSnapshot function to get real time updates and on document reception call a callback
   * passed in params.
   * @param uid
   * @param callback function to execute when a new document is received
   * @returns
   */
  public callbackOnSnapshot(uid: string, callback: (...args: any[]) => void): Unsubscribe {
    return onSnapshot(
      this.getDocRef(uid),
      (doc) => callback(doc.data() as Partial<T>),
      (error) => {
        throw error;
      },
    );
  }

  public async profileExists(uid: string): Promise<boolean> {
    const doc = await getDoc(this.getDocRef(uid));
    return doc?.exists() && !!doc.data();
  }
}
