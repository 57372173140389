import React, { useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { Drafts, NotificationsNone } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';

import { AppBar } from '../../Theme';
import { useStateMachine } from 'little-state-machine';
import { IconButton, useMediaQuery, useTheme, Theme, Grid, Badge, Popover, Typography, List, ListSubheader, ListItem, ListItemText } from '@mui/material';
import { updateSidepanelOpen } from '../../Stores';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { IGetNotificationDto } from '../../Types/notifications.types';
import { parseDate } from '../../Utils/date.utils';

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    '& .button-link': {
      background: theme.palette.secondary.main,
      color: theme.palette.background.default,
    },
  },
}));

export const Topbar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    state: { screen, user },
    actions,
  } = useStateMachine({ updateSidepanelOpen });
  const [ popOpened, setPopOpened ] = React.useState(false);
  const [ notifications, setNotifications ] = React.useState<IGetNotificationDto[]>([]);
  const [ displayedNotifications, setDisplayedNotifications ] = React.useState<IGetNotificationDto[]>([]);

  const handleSidepanelState = (isSidePanelOpen: boolean) => {
    actions.updateSidepanelOpen({
      isSidePanelOpen,
    });
  };

  const handleReadNotification = async (id: string) => {
    try {
      const n = notifications.find(n => n.id === id)

      const notificationIds = notifications.map(notif => {
        if (notif.notification.type === n?.notification.type)
          return notif.id

        return null
      })

      if (user.beneficiary)
        await APIAxios({ ...APIRoutes.PATCHReadBeneficiaryNotifications(), data: notificationIds })
      else if (user.consultant)
        await APIAxios({ ...APIRoutes.PATCHReadConsultantNotifications(), data: notificationIds })
      else return;

      const filteredNotifications = notifications.filter(notif => !notificationIds.includes(notif.id))

      setNotifications(filteredNotifications)
      reduceNotifications()
    } catch (error) {
      console.log(error);
    }
  }

  const reduceNotifications = () => {
    const reducedNotifications: IGetNotificationDto[] = []
    const now = new Date().getTime()

    notifications.forEach(n => {
      const index = reducedNotifications.findIndex(rn => rn.notification.type === n.notification.type)

      if (index === -1)
        reducedNotifications.push(n)
      else if ((now - new Date(n.notification.createdAt).getTime()) < (now - new Date(reducedNotifications[index].notification.createdAt).getTime()))
        reducedNotifications[index] = n
    })

    reducedNotifications.sort((a, b) => new Date(b.notification.createdAt).getTime() - new Date(a.notification.createdAt).getTime())

    setDisplayedNotifications(reducedNotifications)
  }

  const handleReadAllNotifications = async () => {
    try {      
      if (user.beneficiary)
        await APIAxios({ ...APIRoutes.PUTReadBeneficiaryNotifications() })
      else if (user.consultant)
        await APIAxios({ ...APIRoutes.PUTReadConsultantNotifications() })
      else return;

      setNotifications([])
    } catch (error) {
      console.log(error);
    }
  }

  const fetchNotifications = async () => {
    try {
      let res = null;

      if (user.beneficiary)
        res = await APIAxios({ ...APIRoutes.GETBeneficiaryNotifications() })
      else if (user.consultant)
        res = await APIAxios({ ...APIRoutes.GETConsultantNotifications() })
      else res = { data: [] }

      setNotifications(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {    
    reduceNotifications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
    fetchNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppBar
      position="fixed"
      open={screen.isSidePanelOpen}
      sx={{
        top: isSm ? 'auto' : 'unset',
        bottom: isSm ? 0 : 'auto',
      }}
    >
      <Toolbar
        style={{
          justifyContent: 'flex-end',
          padding: isSm ? '0 2rem 0 1rem' : '0 5rem',
        }}
      >
        {isSm && (
          <IconButton
            style={{ display: screen.isSidePanelOpen ? 'none' : 'flex', marginRight: 'auto' }}
            onClick={() => handleSidepanelState(!screen.isSidePanelOpen)}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Grid position='relative' container gap={2} justifyContent="flex-end">
          <Button
            color={notifications.length ? 'secondary' : 'primary'}
            id='notification-button'
            variant='icon-rounded'
            className='button-link'
            style={notifications.length ? { background: theme.palette.secondary.main, color: theme.palette.background.default } : {}}
            onClick={() => setPopOpened(true)}
          >
            <Badge 
              variant='dot'
              color='primary'
              max={99}
              badgeContent={notifications.length}
            >
              <NotificationsNone />
            </Badge>
          </Button>

          <Popover
            open={popOpened}
            onClose={() => setPopOpened(false)}
            anchorEl={document.getElementById('notification-button')}
            anchorOrigin={{
              vertical: isSm ? 'top' : 'bottom',
              horizontal: 'left',
            }}
            style={isSm ? { marginBottom: '1rem' } : { marginTop: '1rem' }}
          >
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap={2}
            >
              <List
                component="nav"
                subheader={
                  <Grid container alignItems="center" justifyContent="space-between" paddingLeft={2} paddingRight={2}>
                    <ListSubheader
                      component="div"
                      style={{ fontWeight: 'bold', color: "black" }}
                    >
                      Notifications
                    </ListSubheader>

                    {displayedNotifications.length > 0 &&
                      <ListSubheader
                        style={{ cursor: 'pointer', opacity: "60%", textDecoration: "underline" }}
                        onClick={handleReadAllNotifications.bind(this)}
                      >
                        Marquer tout comme lu
                      </ListSubheader>
                    }
                  </Grid>
                }
              >
                {displayedNotifications.length > 0 ?
                  <>
                    {displayedNotifications.map((n: IGetNotificationDto) => (
                      <ListItem
                        key={n.id}
                        secondaryAction={
                          <IconButton 
                            edge="end"
                            onClick={handleReadNotification.bind(this, n.id)}
                            style={{ zIndex: 1 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <Link
                          key={n.id}
                          to={n.link}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                          onClick={() => setPopOpened(false)}
                        >
                          <ListItemText
                            primary={n.title}
                            secondary={parseDate(n.notification.createdAt)}
                          />
                        </Link>
                      </ListItem>
                    ))}
                  </>
                :
                  <Grid container alignItems="center" flexDirection="column" gap={2} padding={2}>
                    <Typography>Vous n'avez pas de notification</Typography>
                    
                    <Drafts style={{ opacity: "40%", width: "25%", height: "25%" }} />
                  </Grid>
                }
              </List>
            </Grid>
          </Popover>

          <NavLink to="account-settings" className={({ isActive }) => (isActive ? classes.active : '')}>
            <Button variant="icon-rounded" className="button-link">
              <SettingsIcon />
            </Button>
          </NavLink>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
