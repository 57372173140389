import React from 'react';
import { Grid, Tooltip, Typography, useTheme, Theme } from '@mui/material';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { ArrowForward, DescriptionRounded, FlagRounded, FolderRounded, TrackChangesRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ActionStatus, ActionType, GetActionHistoryDto } from '../../Types/actions.type';

interface TimelineActionProps {
  children?: React.ReactNode;
  actionHistory: GetActionHistoryDto;
  isLast: boolean;
}

export const TimelineActionHistory = ({ actionHistory, isLast }: TimelineActionProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const getBgColor = (action: Partial<GetActionHistoryDto>) => {
    switch (action.type) {
      case 'STATUS_CREATED':
        return theme.palette.secondary.main;
      case 'STATUS_CHANGED':
        return theme.palette.primary.dark;
      case 'DEVICE_CHANGED':
        return theme.palette.primary.main;
      case 'DESCRIPTION_CHANGED':
        return theme.palette.primary.light;
      default:
        return theme.palette.grey[50];
    }
  };

  return (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent style={{ padding: 0 }} />
      <TimelineSeparator>
        <TimelineDot style={{ alignSelf: 'center', color: '#FFFFFF', backgroundColor: getBgColor(actionHistory) }}>
          {actionHistory.type === 'STATUS_CREATED' && <FlagRounded color="inherit" />}
          {actionHistory.type === 'STATUS_CHANGED' && <TrackChangesRounded color="inherit" />}
          {actionHistory.type === 'DEVICE_CHANGED' && <FolderRounded color="inherit" />}
          {actionHistory.type === 'DESCRIPTION_CHANGED' && <DescriptionRounded color="inherit" />}
        </TimelineDot>
        {!isLast && <TimelineConnector sx={{ backgroundColor: (theme) => theme.palette.secondary.main }} />}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            {actionHistory.type === 'STATUS_CREATED' && (
              <Typography variant="body1" color="textPrimary" component="div">
                <strong>Création </strong> du dispositif
              </Typography>
            )}
            {actionHistory.type === 'STATUS_CHANGED' && (
              <Typography variant="body1" color="textPrimary" component="div">
                <strong>Modification </strong> du statut
              </Typography>
            )}
            {actionHistory.type === 'DEVICE_CHANGED' && (
              <Typography variant="body1" color="textPrimary" component="div">
                <strong>Modification </strong> du dispositif
              </Typography>
            )}
            {actionHistory.type === 'DESCRIPTION_CHANGED' && (
              <Typography variant="body1" color="textPrimary" component="div">
                <strong>Modification </strong> de la description
              </Typography>
            )}
          </Grid>
          <Grid item container justifyContent="flex-end" xs={6}>
            <Typography variant="body2" color="textSecondary" component="div" textAlign='end'>
              le {new Date(actionHistory.createdAt).toLocaleDateString()} par{' '}
              <strong className="capital">{actionHistory.author} </strong>
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={12}>
            <Typography variant="body2" color="textSecondary" component="div" fontWeight="600" textAlign='end'>
              <Tooltip title="organisme de l'intervenant" style={{ cursor: 'help' }}>
                <span className="word-break">{actionHistory.organismName ? `(${actionHistory.organismName})` : ''}</span>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: theme.spacing(1) }}>
          {actionHistory.type === 'STATUS_CHANGED' && (
            <Typography className={classes.actionStates} variant="body2" color="textPrimary" component="div">
              {ActionStatus.label(actionHistory.previousState) || ''}
              <ArrowForward fontSize="small" color="action" style={{ margin: '0 .5rem' }} />
              {ActionStatus.label(actionHistory.currentState) || ''}
            </Typography>
          )}
          {actionHistory.type === 'DEVICE_CHANGED' && (
            <Typography className={classes.actionStates} variant="body2" color="textPrimary" component="div">
              <Tooltip placement="top" title={ActionType.label(actionHistory.currentState) || ''}>
                <p className={classes.tooltipedStates}>{ActionType.label(actionHistory.previousState) || ''}</p>
              </Tooltip>
              <ArrowForward fontSize="small" color="action" style={{ margin: '0 .5rem' }} />
              <Tooltip placement="top" title={ActionType.label(actionHistory.currentState) || ''}>
                <p className={classes.tooltipedStates}>{ActionType.label(actionHistory.currentState) || ''}</p>
              </Tooltip>
            </Typography>
          )}
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  timelineItem: {
    borderRadius: '4px',
    padding: '0 10px',
    transition: 'all.2s ease-out',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  timelineContent: {
    flexBasis: '100%',
    // padding: '20px 0 20px 20px',
    marginBottom: '1rem',
  },
  actionStates: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipedStates: {
    cursor: 'default',
    maxWidth: '250px',
    overflow: 'hidden',
  },
  [theme.breakpoints.down('sm')]: {
    timelineItem: {
      padding: 0,
    },
    tooltipedStates: {
      cursor: 'default',
      maxWidth: '100px',
      whiteSpace: 'pre-wrap',
    },
    actionStates: {
      gap: '20px'
    }
  },
}));
