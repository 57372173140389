import { MetaDto } from "./user.types";

type Nullable<T> = { [K in keyof T]: T[K] | null };

export type FactSheet = CreateFactSheetDto;

export type GetFactSheetsListData = {
  id: string;
  title: string;
  description: string;
  videoLink: string;
  isWorkArrest: BothBooleanEnum;
  isBOE: BothBooleanEnum;
  daysCount: number;
  actionTypes: Array<string>;
  goFurther?: boolean;
  goFurtherLabel?: string;
  createdAt: Date;
  updatedAt?: Date;
}

export type CreateFactSheetDto = {
  id?: string;
  title: string;
  description: string;
  videoLink: string;
  isWorkArrest: BothBooleanEnum;
  isBOE: BothBooleanEnum;
  daysCount: number;
  actionTypes: Array<string>;
  goFurther?: boolean,
  goFurtherLabel?: string,
  createdAt?: Date;
  updatedAt?: Date;
}

export type UpdateFactSheetDto = Nullable<CreateFactSheetDto>;

export enum BothBooleanEnum {
  false = 0,
  true = 1,
  both = 2,
}

export type GetFactSheetsListDto = {
  data: GetFactSheetsListData[];
  meta: MetaDto;
}

export type GetFactSheetByIdDto = GetFactSheetsListData;

export type GetFactSheetsAdminDto = {
  id: string;
  title: string;
  description: string;
  videoLink: string;
  isWorkArrest: BothBooleanEnum;
  isBOE: BothBooleanEnum;
  daysCount: number;
  actionTypes: Array<string>;
  goFurther?: boolean;
  goFurtherLabel?: string;
  createdAt: Date;
  updatedAt: Date;
}