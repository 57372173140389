import { makeStyles } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Stack, Theme, Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { useDatatableSearch } from "../../../../Hooks";
import APIAxios, { APIRoutes } from "../../../../API/api.axios";
import { DataTableHeader } from "../../../../Components";
import { IMyFactSheetsRow } from "../fact-sheets.table.types";
import { GetFactSheetsListDto } from "../../../../Types/fact-sheets.types";
import theme, { CustomPagination } from "../../../../Theme";
import { ClickableDatatableCell } from "../../../MyBeneficiaries/Components";
import { handleBothEnumDisplay } from "../../utils/utils";
import { ActionType } from "../../../../Types/actions.type";

const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Titre",
    sortable: true,
    flex: 1,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Titre" />,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1">{params.value || 0}</Typography>
    ),
  },
  {
    field: "videoLink",
    headerName: "Lien vidéo",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Lien vidéo" />,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1">
        <a href={params.value} target="_blank" rel="noreferrer">
          {params.value.split("/").pop()}
        </a>
      </Typography>
    ),
  },
  {
    field: "isWorkArrest",
    headerName: "Arrêt de travail",
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Arrêt de travail" />,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1">
        {handleBothEnumDisplay(params.value)}
      </Typography>
    ),
  },
  {
    field: "isBOE",
    headerName: "Obligation d'emploi",
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Obligation d'emploi" />,
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant="body1">
        {handleBothEnumDisplay(params.value)}
      </Typography>
    ),
  },
  {
    field: "daysCount",
    headerName: "Jours",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Jours" />,
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant="body1">{params.value || 0}</Typography>
    ),
  },
  {
    field: "actionTypes",
    headerName: "Types d'action",
    sortable: false,
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Types d'action" />,
    renderCell: (params: GridRenderCellParams) => (
      <Stack
        direction="column"
        justifyContent="center"
        height="100%"
        style={{ overflowY: "scroll" }}
        gap={1}
      >
        {params?.value.map((type: string) => (
          <Typography
            key={type}
            color="black"
            style={{
              minHeight: 20,
              maxWidth: 220,
              wordWrap: "break-word",
              fontSize: 12,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 16,
              paddingLeft: 8,
              paddingRight: 8,
              userSelect: "none",
            }}
            noWrap
          >
            {ActionType.label(type)}
          </Typography>
        ))}
      </Stack>
    ),
  },
  {
    field: "action",
    headerName: "action",
    sortable: false,
    width: 200,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Actions" />,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack direction="row" style={{ zIndex: 3 }}>
          <ClickableDatatableCell action="show-fact" {...params} />
          <ClickableDatatableCell action="edit-fact" {...params} />
          <ClickableDatatableCell action="delete-fact" {...params} />
        </Stack>
      );
    },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    color: theme.palette.grey[500],
  },
}));

export const FactSheetsDataTable = () => {
  const classes = useStyles();

  const [data, setData] = useState<IMyFactSheetsRow[] | undefined>(undefined);
  const { search } = useDatatableSearch();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);

  const [factSheets, setFactSheets] = useState<
    GetFactSheetsListDto | undefined
  >(undefined);

  const getFactSheets = useCallback(async () => {
    try {
      const res = await APIAxios({
        ...APIRoutes.GETFactSheets(page, search),
      });

      if (res.data as GetFactSheetsListDto) {
        setFactSheets(res.data);
        setData(res.data || []);
      } else {
        enqueueSnackbar(
          "Une erreur est survenue. Veuillez réessayer plus tard."
        );
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, enqueueSnackbar]);

  useEffect(() => {
    getFactSheets();
  }, [getFactSheets]);

  if (data === undefined) return <div>Chargement ...</div>;

  if (!data.length)
    return (
      <>
        <Typography variant="h3">
          Il semble que vous n&apos;aillez pas de fiche d'information.
        </Typography>
        <Typography variant="subtitle1">
          Vous pouvez en créer une en cliquant directement sur le bouton
          ci-dessus &apos;Créer une fiche&apos;
        </Typography>
      </>
    );

  return (
    <DataGrid
      getRowClassName={(params) => {
        return params.row.isDeleted ? classes.disabledRow : "";
      }}
      rows={data}
      columns={columns}
      onPageChange={setPage}
      pageSize={factSheets?.meta?.take}
      autoHeight
      disableSelectionOnClick
      density="comfortable"
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
          borderBottom: "1px solid #e9e9e9",
        },
        "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
          borderTop: "1px solid #e9e9e9",
        },
        "&.MuiDataGrid-root *:focus-within": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
          overflow: "hidden",
          whiteSpace: "normal",
          textAlign: "center",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell": {
          overflow: "hidden",
          whiteSpace: "normal",
          textAlign: "center",
          borderBottom: "1px solid #e9e9e9",
        },
      }}
      rowCount={factSheets?.meta?.itemCount}
      components={{
        Pagination: CustomPagination,
      }}
    />
  );
};
