import { GenericCard, ProfileAvatar } from '../../Components';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { GetConsultantDto, PostOccupiedEnum } from '../../Types';

export interface ConsultantsProps {
  user: GetConsultantDto;
}

export const Consultants = (props: ConsultantsProps) => {
  const { user } = props;

  const formatPhone = (phone: string | null | undefined) => {
    if (!phone) return;
    return phone
      .replace(/\s+/gu, '')
      .match(/.{1,2}/g)
      ?.join(' ');
  };
  return (
    <GenericCard variant={user?.isDeleted ? 'fixedSizeDisabled' : 'fixedSize'}>
      {user?.isDeleted && (
        <Box
          sx={{
            position: 'absolute',
            right: '20px',
            fontSize: '14px',
            padding: (theme) => theme.spacing(1),
            borderRadius: (theme) => theme.shape.borderRadius,
            background: (theme) => theme.secondaryPalette.lightPurple,
            color: (theme) => theme.secondaryPalette.purple,
          }}
        >
          Profil inactif
        </Box>
      )}
      <Grid container gap={2}>
        <Grid item xs={12}>
          <ProfileAvatar size={75} disabled={user?.isDeleted}></ProfileAvatar>
        </Grid>
        <Grid item xs={12} container gap={2}>
          <Grid item xs={12} container direction="column" className="capital" gap={1}>
            <Typography variant="h3">
              {user?.lastName?.toUpperCase() || '-'}&nbsp;{user?.firstName || '-'}
            </Typography>
            <Typography variant="body1">{user.postOccupied ? PostOccupiedEnum.label(user.postOccupied) : '-'}</Typography>
            <Typography variant="body1">{user?.organism?.name || '-'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container gap={2}>
            <PhoneInTalkOutlinedIcon color={user?.isDeleted ? 'disabled' : 'primary'} />
            <Typography variant="subtitle1">{formatPhone(user?.phoneNumber) || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </GenericCard>
  );
};
