import AbstractProfileService from '../base.profile.service';
import { TBeneficiary, TBeneficiaryProfile, TConsultantProfile } from '../../../Types';
import {
  setDoc,
  Firestore,
  getDoc,
  doc,
  arrayUnion,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { db, globalGetDocRef } from '../../../Configuration/firebase.config';
import { DatabaseCollection } from '../../../Configuration/firebase.config';

class ConsultantProfileService extends AbstractProfileService<TConsultantProfile> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(db: Firestore, dbCollection: DatabaseCollection) {
    super(db, dbCollection);
  }

  public async create(uid: string): Promise<void> {
    const consultantProfile: TConsultantProfile = {
      organism: '',
      postOccupied: '',
    };

    try {
      await setDoc(this.getDocRef(uid), consultantProfile);
    } catch (error) {
      throw error;
    }
  }

  public async getFollowedProfiles(followeBeneficiariesIds: string[]): Promise<TBeneficiary[]> {
    const profiles: TBeneficiary[] = [];
    if (followeBeneficiariesIds?.length) {
      for (const beneficiaryId of followeBeneficiariesIds) {
        const beneficiaryProfile = await getDoc(doc(this._db, 'beneficiary', beneficiaryId));
        const beneficiaryData = beneficiaryProfile.data();
        const userProfileData = (await getDoc(doc(this._db, 'profile', beneficiaryId))).data();
        profiles.push({
          beneficiaryId: beneficiaryProfile.id,
          ...beneficiaryData,
          ...userProfileData,
        } as TBeneficiary);
      }
    }
    return profiles;
  }

  public async handleInvitation(uid: string, invitationId: string, accept: boolean) {
    // Get the invited userid
    const userDocRef = this.getDocRef(uid);
    // Get the invited invitation by id
    const invitationDocRef = doc(this._db, userDocRef.path, '/invitations', `/${invitationId}`);
    // Get the invitation document
    const invitationDoc = await getDoc(invitationDocRef);
    if (invitationDoc.exists()) {
      const data = invitationDoc.data();
      const { from } = data;
      //Get a reference to who is inviting uid
      const invitantDocRef = globalGetDocRef<TBeneficiaryProfile>(`beneficiary/${from}`);
      // If i accept the invitation, update my followingConsultant and update the invitant followedBeneficiaries array
      if (accept) {
        await updateDoc(invitantDocRef, {
          followingConsultants: arrayUnion(uid),
        });
        await updateDoc(userDocRef, {
          followedBeneficiaries: arrayUnion(from),
        });
      }
      // At the end of the process delete the invitation
      await deleteDoc(invitationDocRef);
    }
  }

  // public async createInvitation(uid: string, invitationPayload: TInvitationQuery) {
  //   try {
  //     const { userMail, searchUser, senderFullName, message } = invitationPayload;
  //     if (userMail?.length) {
  //       await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/consultant/invite`, {
  //         id: uid,
  //         email: invitationPayload.userMail,
  //         message,
  //       });
  //     } else if (!!searchUser) {
  //       if (searchUser.email?.length) {
  //         await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/consultant/invite`, {
  //           id: uid,
  //           email: searchUser.email,
  //           message,
  //         });
  //       } else {
  //         const invitedDocRef = globalGetDocRef<TBeneficiaryProfile>(`beneficiary/${searchUser.uid}`);
  //         const invitedCollectionRef = collection(
  //           this._db,
  //           invitedDocRef.path,
  //           '/invitations',
  //         ) as CollectionReference<TInvitation>;
  //         await addDoc(invitedCollectionRef, {
  //           from: uid,
  //           message: message,
  //           user: senderFullName,
  //           invitantRole: 'consultant',
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     throw Error("L'invitation n'a pas abouti.");
  //   }
  // }

  public async profileExists(uid: string): Promise<boolean> {
    const doc = await getDoc(this.getDocRef(uid));
    return doc?.exists();
  }
}

export const consultantProfileService = new ConsultantProfileService(db, 'consultant');
