import { useCallback, useEffect, useState } from 'react';
import { GetConsultantDto, GetOrganismDto } from '../../../Types';
import { Button, Grid, IconButton, Stack, Tab, Tabs, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { Add, ArrowBack } from '@mui/icons-material';
import { ConsultantsDataTable } from '../../MyConsultants';
import { DatatableSearchProvider } from '../../../Providers';
import { NewActionsDataTable } from '../../MyActions/Components/new-actions-table.component';
import Edit from '../../../Assets/edit.svg';
import Search from '../../../Assets/search.svg'
import { CreateOrganismPopup } from '../CreateOrganism/create-organism.component';
import { useDatatableSearch } from '../../../Hooks';
import { FilterPopup } from '../../../Components/Molecules/filter-popup.molecule';
import { SelectOption } from '../../../Components/Molecules/organism-search.molecule';

interface  OrganismProfileProps {
  organism?: GetOrganismDto;
  handleUpdateOrganism: (value: GetOrganismDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '10px', 
    backgroundColor: 'white',
    fontSize: '13px',
    padding: '1.3rem',
    height: 'max-content'
  },
  title: {
    fontWeight: 600,
    color: 'black',
    paddingBottom: '0.7rem',
  },
  name: {
    color: 'rgb(155, 159, 164)',
    fontWeight: 500,
  },
  value: {
    color: 'black',
    fontWeight: 400,
  },
  premium: {
    backgroundColor: 'rgba(255, 185, 92, 0.1) !important',
    color: 'rgba(254, 174, 68)',
  },
  inactive: {
    backgroundColor: 'rgba(155, 159, 164, 0.2)',
  },
  image: {
    height: '70px',
    width: '70px',
    borderRadius: '8px',
    backgroundColor: 'rgba(90, 173, 178, 0.1)',
    borderColor: 'transparent',
  }
}))

const OrganismProfileComponent = (props: OrganismProfileProps) => {
  const { organism, handleUpdateOrganism } = props;
  const classes = useStyles();

  const [popUpOpen, setPopUpOpen] = useState(false);

  if (!organism) {
    return <></>
  }

  return (
    <Grid container>
      {popUpOpen && (
        <CreateOrganismPopup id={organism.id} handleUpdate={(value) => {
          handleUpdateOrganism(value)
          setPopUpOpen(false)
        }} handleClose={() => setPopUpOpen(false)} />
      )}
      <Grid item container gap={2}>
        <Grid item className={classes.card} xs> 
          <Stack gap={1}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
              <Typography className={classes.title}>
                Informations
              </Typography>
              <IconButton size='small' onClick={() => setPopUpOpen(true)} sx={{width: '20px', height: '20px', marginBottom: '10px'}}>
                <img src={Edit} alt=''/>
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Nom du représentant</Typography>
              <Typography className={classes.value}>{organism.lastName || '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Prénom du représentant</Typography>
              <Typography className={classes.value}>{organism.firstName || '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Téléphone</Typography>
              <Typography className={classes.value}>{organism.phoneNumber || '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Adresse</Typography>
              <Typography className={classes.value}>{organism.address ?? '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Ville</Typography>
              <Typography className={classes.value}>{(organism.postcode || '-') + ' ' + (organism.city || '-')}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Adresse</Typography>
              <Typography className={classes.value}>{organism.address || '-'}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.name}>Adresse mail</Typography>
              <Typography className={classes.value}>{organism.email}</Typography>
            </Stack>
          </Stack>
        </Grid>
        {/*<Grid item className={classes.card}>*/}
        {/*  <Stack gap={1}>*/}
        {/*    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={5}>*/}
        {/*      <Typography className={classes.title}>*/}
        {/*        Logo de l'organisme*/}
        {/*      </Typography>*/}
        {/*      <IconButton size='small' onClick={() => setPopUpOpen(true)} sx={{width: '20px', height: '20px', marginBottom: '10px'}}>*/}
        {/*        <img src={Edit} alt=''/>*/}
        {/*      </IconButton>*/}
        {/*    </Stack>*/}
        {/*    <Stack className={classes.image} direction="row" justifyContent="start" gap={2} alignSelf='center'>*/}
        {/*      {organism.photoUrl && (*/}
        {/*        <img src={organism.photoUrl} alt='' style={{height: 'inherit', borderRadius: 'inherit'}} />*/}
        {/*      )}*/}
        {/*    </Stack>*/}
        {/*  </Stack>*/}
        {/*</Grid>*/}
        <Grid item xs></Grid>
      </Grid>
      <Grid item>
        <Button></Button>
      </Grid>
    </Grid>
  )
}

export const OrganismDetail = () => {

  const { organismId } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const [organism, setOrganism] = useState<GetOrganismDto | undefined>(undefined);
  const [filterPopUpOpen, setFilterPopUpOpen] = useState(false);

  const getOrganism = useCallback(async () => {
    if (organismId) {
      try { 
        const res = await APIAxios({...APIRoutes.GETOrganism(organismId)});
        setOrganism(res.data);
      } catch (err) {
      }
    }
  }, [organismId]);

  const getConsultants = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({...APIRoutes.GETConsultants(1, q)})
    .then((res) => {
      if (res.data) {
        return res.data.data.map((it: GetConsultantDto) => ({ label: it.firstName + ' ' + it.lastName, value: it.id, data: it }));
      }
    })
  }

  const addConsultant = useCallback(async (consultantId: string) => {
    try {
      const res = await APIAxios({...APIRoutes.PATCHConsultantById(consultantId), 
        data: { 
          organismId: organismId 
      }})
      if (res.data as GetConsultantDto) {
        setFilterPopUpOpen(false);
      }
    } catch (err) {

    }
  }, [organismId]);

  useEffect(() => {
    getOrganism();
  }, [getOrganism]);

  const renderTab = () => {
    switch (tab) {
      case 0:
        return <OrganismProfileComponent organism={organism} handleUpdateOrganism={setOrganism} />;
      case 1:
        return (
          <DatatableSearchProvider>
            {filterPopUpOpen && (
              <FilterPopup 
                title='Ajouter un consultant'
                placeholder="Nom du consultant"
                handleSelect={(value) => {
                  addConsultant(value.data.id);
                }}
                getOptions={getConsultants}
                handleClose={() => setFilterPopUpOpen(false)} />
            )}
              <SearchConsultant setFilterPopupOpen={setFilterPopUpOpen}/>
              <ConsultantsDataTable organismId={organism?.id} />
          </DatatableSearchProvider>
        )
      case 2:
        return <NewActionsDataTable organismId={organism?.id} />
    }
  }

  return (
    <>
    <Stack spacing={2}>
      <Stack direction='row'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack sx={{color: 'black'}} />
        </IconButton>
        <Typography variant='h1' sx={{color: 'black'}}>
          {organism?.name}
        </Typography>
      </Stack>
      <Tabs value={tab} onChange={(e, v) => setTab(v)}
        sx={{width: '100%', borderBottom: 'solid 1px rgb(233, 233, 233)'}}>
        <Tab label="Informations" />
        <Tab label='Consultants' />
        <Tab label='Dispositifs' />
      </Tabs>
      {renderTab()}
      <Outlet/>
    </Stack>
    </>
  )
}

interface SearchConsultantProps {
  setFilterPopupOpen: (v: boolean) => void;
}

const SearchConsultant = (props: SearchConsultantProps) => {
  
  const { setFilterPopupOpen } = props;
  const { search, updateSearch } = useDatatableSearch();

  return (
    <Stack direction='row' gap={2}>
    <TextField
        fullWidth
        value={search}
        onChange={(e) => {
          updateSearch(e.target.value)
        }}
        placeholder="Rechercher un(e) consultant(e)"
        InputProps={{
          startAdornment: <img src={Search} alt='' />
        }}
      />
      <Button startIcon={<Add />} sx={{width: '300px'}} variant='contained' onClick={() => { setFilterPopupOpen(true) }}>
        Ajouter un consultant
      </Button>
    </Stack>
  )
}