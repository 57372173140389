import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { actionService } from '../../../Services';
import { useActions } from '../../../Hooks';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import { useStateMachine } from 'little-state-machine';
import { ActionStatus } from '../../../Types/actions.type';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { useSnackbar } from 'notistack';

export const MyActionsFilter = () => {
  const { actions } = useActions();
  const {
    state: {
      user: { id, consultant }, 
    },
  } = useStateMachine();
  const { statusFilters, filterByStatus, dateFilter, filterByTypeOrName, typeOrNameFilter, filterByDate, beneficiaryId } =
    useActions();
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const handleExport = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETActionsCSV(typeOrNameFilter || undefined, statusFilters, beneficiaryId, dateFilter?.from?.toISOString(), dateFilter?.to?.toISOString())})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'actions.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, id]);

  useEffect(() => {
    if (dateRange[0] === null) {
      filterByDate({
        isValid: false,
      });
    } else {

      var actualDate = dateRange[1];
      if (!!actualDate) {
        var endDate = new Date(actualDate.getFullYear()
                           ,actualDate.getMonth()
                           ,actualDate.getDate()
                           ,23,59,59);
      filterByDate({
        from: dateRange[0] as Date,
        to: endDate as Date,
        isValid: true,
      });
      }
    }
  }, [dateRange, filterByDate]);

  const clearDateRange = () => {
    setDateRange([null, null]);
  };

  const handleClearTypeOrNameFilter = () => {
    if (typeOrNameFilter?.length) {
      filterByTypeOrName('');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h2">Actions</Typography>
        <Button onClick={handleExport} variant="outlined" disabled={!consultant?.isPremium}>
          Exporter en csv
        </Button>
      </Grid>
      <Grid container marginBottom={4} gap={2} justifyContent="space-between">
        <Grid container item xs={12} md={6.7} gap={2} alignItems="flex-start" alignContent="space-between">
          <Grid item container gap={1} flexWrap={isSm ? 'wrap' : 'nowrap'}>
            <Button
              variant="filter"
              style={{
                whiteSpace: 'break-spaces',
              }}
              className={statusFilters.includes(ActionStatus.TO_BE_REALIZED) ? 'active TODO' : ''}
              onClick={() => filterByStatus(ActionStatus.TO_BE_REALIZED)}
            >
              {actionService.actionStatus.TODO} ({actions?.customMeta.toBeRealized})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.IN_PROGRESS) ? 'active PENDING' : ''}
              onClick={() => filterByStatus(ActionStatus.IN_PROGRESS)}
            >
              {actionService.actionStatus.PENDING} ({actions?.customMeta.inProgress})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.COMPLETED) ? 'active DONE' : ''}
              onClick={() => filterByStatus(ActionStatus.COMPLETED)}
            >
              {actionService.actionStatus.DONE} ({actions?.customMeta.completed})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.CANCELLED) ? 'active ABANDONED' : ''}
              onClick={() => filterByStatus(ActionStatus.CANCELLED)}
            >
              {actionService.actionStatus.ABANDONED} ({actions?.customMeta.cancelled})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.REFUSED) ? 'active REFUSED' : ''}
              onClick={() => filterByStatus(ActionStatus.REFUSED)}
            >
              {actionService.actionStatus.REFUSED} ({actions?.customMeta.refused})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.APPROVED) ? 'active APPROVED' : ''}
              onClick={() => filterByStatus(ActionStatus.APPROVED)}
            >
              {actionService.actionStatus.APPROVED} ({actions?.customMeta.approved})
            </Button>
            <Button
              style={{
                whiteSpace: 'break-spaces',
              }}
              variant="filter"
              className={statusFilters.includes(ActionStatus.RECOMMENDED) ? 'active RECOMMENDED' : ''}
              onClick={() => filterByStatus(ActionStatus.RECOMMENDED)}
            >
              {actionService.actionStatus.RECOMMENDED} ({actions?.customMeta.recommended})
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={5} alignItems="flex-start" justifyContent="space-between" gap={2}>
          <Grid item flexGrow={1}>
            <DateRangePicker
              inputFormat="dd/MM/yyyy"
              toolbarTitle="Selectionnez une période"
              cancelText="Annuler"
              onChange={setDateRange}
              value={dateRange}
              renderInput={({ inputProps, ...startProps }, endProps) => {
                const startValue = inputProps?.value;
                delete inputProps?.value;
                return (
                  <TextField
                    fullWidth
                    size="small"
                    {...startProps}
                    label="Période"
                    placeholder="Période"
                    inputProps={inputProps}
                    value={startValue ? `Du ${startValue} au ${endProps?.inputProps?.value}` : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ClearRoundedIcon onClick={clearDateRange} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <TextField
              fullWidth
              size="small"
              placeholder="Type d'action ou bénéficiaire"
              value={typeOrNameFilter || ''}
              onChange={(e) => filterByTypeOrName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearRoundedIcon onClick={handleClearTypeOrNameFilter} style={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};
