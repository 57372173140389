import { BothBooleanEnum } from "../../../Types/fact-sheets.types";

export const handleBothEnumDisplay = (value: number) => {
  switch (value) {
    case BothBooleanEnum.both:
      return "Peu importe";
    case BothBooleanEnum.false:
      return "Non";
    case BothBooleanEnum.true:
      return "Oui";
    default:
      return "";
  }
};

export const handleBothEnumKeysDisplay = (value: string) => {
  switch (value) {
    case "both":
      return "Peu importe";
    case "false":
      return "Non";
    case "true":
      return "Oui";
    default:
      return "";
  }
};
