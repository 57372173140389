import HomeIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EventIcon from '@mui/icons-material/EventRounded';
import EventOultinedIcon from '@mui/icons-material/EventOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOulinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Mail, MailOutline } from '@mui/icons-material';

import { TScreenSidepanelAction } from '../Types';
import AccountActive from '../Assets/account_active.svg';
import Account from '../Assets/account_inactive.svg';
import ConsultantActive from '../Assets/consultant_active.svg';
import Consultant from '../Assets/consultant_inactive.svg';
import OrganismActive from '../Assets/organism_active.svg';
import Organism from '../Assets/organism_inactive.svg';
import SheetActive from '../Assets/sheets_active.svg';
import Sheet from '../Assets/sheets.svg';
import PersonActive from '../Assets/person_active.svg';
import Person from '../Assets/person_inactive.svg';

export namespace SidePanelActionsConfiguration {
  export const beneficiary: TScreenSidepanelAction[] = [
    {
      title: 'Tableau de bord',
      navigateTo: '/beneficiary/dashboard',
      icon: <HomeOutlinedIcon />,
      activeIcon: <HomeIcon color="primary" />,
    },
    {
      title: 'Mon parcours',
      navigateTo: '/beneficiary/journey',
      icon: <EventOultinedIcon />,
      activeIcon: <EventIcon color="primary" />,
    },
    {
      title: 'Mes consultants',
      navigateTo: '/beneficiary/my-consultants',
      icon: <AccountCircleOulinedIcon />,
      activeIcon: <AccountCircleIcon color="primary" />,
    },
    {
      title: 'Mes informations',
      navigateTo: '/beneficiary/my-informations',
      icon: <AssignmentOutlinedIcon />,
      activeIcon: <AssignmentIcon color="primary" />,
    },
  ];

  export const consultant: TScreenSidepanelAction[] = [
    {
      title: 'Tableau de bord',
      navigateTo: '/consultant/dashboard',
      icon: <HomeOutlinedIcon />,
      activeIcon: <HomeIcon color="primary" />,
    },
    {
      title: 'Mes actions',
      navigateTo: '/consultant/my-actions',
      icon: <EventOultinedIcon />,
      activeIcon: <EventIcon color="primary" />,
    },
    {
      title: 'Mes bénéficiaires',
      navigateTo: '/consultant/my-beneficiaries/all',
      siblingRoutes: [
        '/consultant/my-beneficiaries/all',
        '/consultant/my-beneficiaries/informations',
        '/consultant/my-beneficiaries/journey',
      ],
      icon: <AccountCircleOulinedIcon />,
      activeIcon: <AccountCircleIcon color="primary" />,
    },
    {
      title: 'Mes invitations',
      navigateTo: '/consultant/my-invitations',
      siblingRoutes: [],
      icon: <MailOutline />,
      activeIcon: <Mail color="primary" />,
    }
  ];

  export const organism: TScreenSidepanelAction[] = [
    {
      title: 'Mes consultants',
      navigateTo: '/organism/consultants',
      siblingRoutes: [

      ],
      icon: <img src={Consultant} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={ConsultantActive} style={{height: '15px', width: '15px'}} alt='' />
    },
    {
      title: 'Compte',
      navigateTo: '/organism/account-settings',
      icon: <img src={Account} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={AccountActive} style={{height: '15px', width: '15px'}} alt='' />
    }
  ];

  export const admin: TScreenSidepanelAction[] = [
    {
      title: 'Bénéficiaires',
      navigateTo: '/admin/beneficiaries',
      siblingRoutes: [
        '/admin/beneficiaries/',
      ],
      icon: <img src={Person} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={PersonActive} style={{height: '15px', width: '15px'}} alt='' />
    },
    {
      title: 'Consultants',
      navigateTo: '/admin/consultants',
      siblingRoutes: [
        '/admin/consultants/',
      ],
      icon: <img src={Consultant} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={ConsultantActive} style={{height: '15px', width: '15px'}} alt='' />
    },
    {
      title: 'Organismes',
      navigateTo: '/admin/organisms',
      siblingRoutes: [
        '/admin/organisms/',
      ],
      icon: <img src={Organism} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={OrganismActive} style={{height: '15px', width: '15px'}} alt='' />
    },
    {
      title: "Fiches",
      navigateTo: '/admin/fact-sheets',
      siblingRoutes: [
        '/admin/fact-sheets/',
      ],
      icon: <img src={Sheet} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={SheetActive} style={{height: '15px', width: '15px'}} alt='' />
    },
    {
      title: 'Compte',
      siblingRoutes: [
        '/admin/account-settings',
      ],
      navigateTo: '/admin/account-settings',
      icon: <img src={Account} style={{height: '15px', width: '15px'}} alt='' />,
      activeIcon: <img src={AccountActive} style={{height: '15px', width: '15px'}} alt='' />
    }
  ]
}
