import { GetBeneficiaryDto, GetConsultantDto, GetOrganismDto, TBeneficiaryProfile, TUserRole, UserState } from '../Types';

type TSerializable<K extends string, T> = {
  [key in K]: T;
};

export const BENEFICIARY_STATUS = [
  'Sans emploi',
  'Salarié',
  'Agent de la fonction publique',
  'Intérimaire',
  "Chef d'entreprise",
  'Indépendant',
];

export const BENEFICIARY_CONTRACT_TYPE = ['Non applicable', 'CDI', 'CDD', 'Contractuelle', 'Apprentissage'];

export const SAMI_ASSIST_CONTACT_EMAIL = 'assistant.sami@tech4work.fr';

export const BENEFICIARY_LEVEL_SCHOOL = [
  'Pas de diplôme',
  'Brevet',
  'Bac professionnel',
  'Bac + 2',
  'Bac + 3',
  'Bac + 4',
  'Bac + 5 ou +',
];

export const BENEFICIARY_LANGUAGE_LEVEL = [
  'A1 Débutant',
  'A2 Connaissance de base',
  'B1 Connaissance améliorées',
  'B2 Autonomie linguistique',
  'C1 Connaissances expertes',
  'C2 Maîtrise',
];

export const BENEFICIARY_BOE_TYPE = [
  {
    key: 'RQTH (reconnaissance qualité travailleur en situation de handicap)',
    label: 'RQTH (reconnaissance qualité travailleur en situation de handicap)',
  },
  { key: 'Invalidité de catégorie 1, 2, 3', label: 'Invalidité de catégorie 1, 2, 3' },
  {
    key: 'Incapacité partielle permanente > 10 %',
    label: 'Incapacité partielle permanente > 10 % (Maladie professionnelle, accident du travail)',
  },
];

export const BENEFICIARY_TYPE_WORK_ARREST = [
  'Arrêt Maladie',
  'Accident du travail',
  'Maladie Professionnelle',
  'CLM (Congé Longue Maladie)',
  'CLD (Congé Longue Durée)',
];

export const BENEFICIARY_GENDER: TSerializable<string, { key: string; label: string }> = {
  male: {
    key: 'male',
    label: 'Homme',
  },
  female: {
    key: 'female',
    label: 'Femme',
  },
};

export const DEFAULT_INVITATION_MESSAGE: TSerializable<TUserRole, string> = {
  beneficiary: `Bonjour,

Je souhaiterai partager mon parcours avec vous afin que vous puissiez m'aider.
Merci d'avance.`,
  consultant: `Bonjour,
  
Je souhaiterai pouvoir accéder à votre parcours afin de pouvoir vous aider.
Merci d'avance`,
  organism: '',
  admin: "",
};

export const UserProfileRequiredFields: (keyof UserState)[] = [
  // 'gender',
  // 'firstname',
  // 'lastname',
  // 'birthdate',
  // 'city',
  'id',
];

export const BeneficiaryProfileRequiredFields2: (keyof GetBeneficiaryDto)[] = [
  'gender',
  'email',
  'firstName',
  'lastName',
  'address',
  'ape',
  'contract',
  'postcode',
  'isBOE',
  'isWorkArrest',
  'job',
  'language',
  'languageLevel',
  'levelSchool',
  // 'postOccupied',
  'status',
]

export const BeneficiaryProfileRequiredFields: (keyof TBeneficiaryProfile)[] = [
  'codeAPE',
  'contractType',
  'departmentLocation',
  'isBOE',
  'isWorkArrest',
  'formation',
  'language',
  'languageLevel',
  'levelSchool',
  'postOccupied',
  'status',
];

export const ConsultantProfileRequiredFields: (keyof GetConsultantDto)[] = [
  'email', 
  'lastName',
  'firstName',
  'gender',
  'address',
  'postcode',
  'city'
];

// TODO: complete organism full profile
export const OrganismProfileRequiredFields: (keyof GetOrganismDto)[] = [];