import { GetBeneficiaryLightDto } from "../../../Types";
import AbstractDataTableService from "../data-table.base.service";

export interface IMyBeneficiariesRow {
  id: string;
  isDeleted: boolean;
  name: string;
  age: number;
  statusBOE?: string;
  dateWorkArrest?: Date;
  isSuspended: boolean;
  isBOE: boolean;
  isWorkArrest: boolean;
  typeWorkArrest: string;
  isHelpNeeded: boolean | null;
}

class FollowedBeneficiariesDataTableService extends AbstractDataTableService<
  GetBeneficiaryLightDto,
  IMyBeneficiariesRow
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pageSize: number) {
    super(pageSize);
  }

  private getUserAge(birth: string): number {
    if (birth?.length === 0) {
      return 0;
    }
    const today = new Date();
    const birthdate = new Date(birth);
    let age = today.getFullYear() - birthdate.getFullYear();
    const m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    return age;
  }

  protected buildRow(
    beneficiaryProfile: GetBeneficiaryLightDto
  ): IMyBeneficiariesRow {
    return {
      id: beneficiaryProfile.id,
      isDeleted: beneficiaryProfile.isDeleted || false,
      name:
        beneficiaryProfile.lastName.toUpperCase() +
        " " +
        beneficiaryProfile.firstName,
      age: beneficiaryProfile.age || 0,
      statusBOE: beneficiaryProfile?.statusBOE,
      isSuspended: beneficiaryProfile.isSuspended,
      dateWorkArrest: beneficiaryProfile.dateWorkArrest,
      isBOE: beneficiaryProfile.isBOE,
      isWorkArrest: beneficiaryProfile.isWorkArrest,
      typeWorkArrest: beneficiaryProfile.typeWorkArrest,
      isHelpNeeded: beneficiaryProfile.isHelpNeeded
    };
  }
}

export const followedBeneficiariesDataTableService =
  new FollowedBeneficiariesDataTableService(10);
