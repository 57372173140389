import { useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import APIAxios, { APIRoutes } from "../../../API/api.axios";
import { NewHeader } from "../../../Components/Molecules/new-title.component";
import { Popup } from "../../../Theme";
import Close from "../../../Assets/cross_close.svg";

export const DeleteFactSheetPopup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { factSheetId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const deleteFactSheetById = async () => {
    if (!factSheetId) return;

    setIsLoading(true);

    try {
      await APIAxios({ ...APIRoutes.DELETEFactSheet(factSheetId) });

      enqueueSnackbar("La fiche a bien été supprimée", { variant: "success" });

      navigate("/admin/fact-sheets");
      window.location.reload();
    } catch (error) {
      console.warn("error", error);

      enqueueSnackbar(
        "Une erreur est survenue lors de la suppression de la fiche",
        { variant: "error" }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popup open={true} maxWidth="md" fullWidth>
      <Stack padding={4} gap={3}>
        <Stack justifyContent="space-between" direction="row" alignItems="top">
          <NewHeader title="Supprimer la fiche" />

          <IconButton onClick={() => navigate("/admin/fact-sheets")}>
            <img src={Close} alt="Croix" />
          </IconButton>
        </Stack>

        <Typography variant="body1">
          Êtes-vous sûr de vouloir supprimer cette fiche ?
        </Typography>

        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/admin/fact-sheets")}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={deleteFactSheetById}
            disabled={isLoading}
          >
            Supprimer
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};
