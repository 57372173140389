import { FieldErrors } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import {
  BothBooleanEnum,
  FactSheet,
} from "../../../../Types/fact-sheets.types";
import { ActionType } from "../../../../Types/actions.type";
import { handleBothEnumDisplay } from "../../utils/utils";

interface ISeconfStepFactSheetProps {
  factSheet?: FactSheet;
  errors: FieldErrors;
  setErrors: (errors: FieldErrors) => void;
  updateFactSheet: (
    name: string
  ) => (value: string | number | string[] | boolean) => void;
}

export const SecondStepFactSheet = ({
  factSheet,
  errors,
  updateFactSheet,
}: ISeconfStepFactSheetProps) => {
  const BothEnumKeys = Object.keys(BothBooleanEnum).filter((k) =>
    isNaN(Number(k))
  );
  const formHelperStyle: React.CSSProperties = {
    color: "red",
    backgroundColor: "transparent",
  };

  return (
    <Grid container gap={2} direction="column">
      <Typography textAlign="justify">
        Veuillez définir les conditions de visibilité de votre fiche
        d'information. Ces conditions permettront de déterminer qui pourra voir
        votre contenu.
      </Typography>

      <Typography textAlign="start">
        La fiche doit apparaitre pour les bénéficiaires :
      </Typography>

      <Stack direction="row" gap={3}>
        <FormControl fullWidth>
          <InputLabel id="isWorkArrestSelectLabel">
            En arrêt de travail ?
          </InputLabel>

          <Select
            id="isWorkArrestSelect"
            labelId="isWorkArrestSelectLabel"
            label="En arrêt de travail ?"
            placeholder="En arrêt de travail ?"
            error={!!errors?.isWorkArrest}
            onChange={(e) =>
              updateFactSheet("isWorkArrest")(
                BothBooleanEnum[e.target.value as keyof typeof BothBooleanEnum]
              )
            }
            renderValue={(value) => {
              return handleBothEnumDisplay(value);
            }}
            value={factSheet?.isWorkArrest}
          >
            {BothEnumKeys.map((key, index) => {
              return (
                <MenuItem value={key} key={index}>
                  {handleBothEnumDisplay(
                    BothBooleanEnum[key as keyof typeof BothBooleanEnum]
                  )}
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText style={formHelperStyle}>
            {errors?.isWorkArrest}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="isBOELabel">En obligation d'emploi ?</InputLabel>

          <Select
            id="isBOESelect"
            labelId="isBOELabel"
            label="En obligation d'emploi ?"
            placeholder="En obligation d'emploi ?"
            error={!!errors?.isBOE}
            onChange={(e) =>
              updateFactSheet("isBOE")(
                BothBooleanEnum[e.target.value as keyof typeof BothBooleanEnum]
              )
            }
            renderValue={(value) => {
              return handleBothEnumDisplay(value);
            }}
            value={factSheet?.isBOE}
          >
            {BothEnumKeys.map((key, index) => {
              return (
                <MenuItem value={key} key={index}>
                  {handleBothEnumDisplay(
                    BothBooleanEnum[key as keyof typeof BothBooleanEnum]
                  )}
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText style={formHelperStyle}>
            {errors?.isBOE}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <TextField
            fullWidth
            minRows={3}
            type="number"
            color="primary"
            placeholder="A partir de combien de jours d'arrêt ?"
            helperText={errors?.daysCount}
            error={!!errors?.daysCount}
            label="A partir de combien de jours d'arrêt ?"
            onChange={(e) => updateFactSheet("daysCount")(e.target.value)}
            value={factSheet?.daysCount}
          />
        </FormControl>
      </Stack>

      <Stack direction="column" gap={3} style={{ maxWidth: 600 }}>
        <Typography textAlign="start">
          La fiche doit apparaitre pour les actions :
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="actionTypesLabel">De type</InputLabel>

          <Select
            id="actionTypesSelect"
            labelId="actionTypesLabel"
            label="De type"
            placeholder="Type d'action"
            multiple
            multiline
            onChange={(e) => {
              updateFactSheet("actionTypes")(e.target.value);
            }}
            error={!!errors?.actionTypes}
            value={factSheet?.actionTypes || []}
          >
            {ActionType.actionTypes(true, true).map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  {ActionType.label(value)}
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText style={formHelperStyle}>
            {errors?.actionTypes}
          </FormHelperText>
        </FormControl>
      </Stack>

      <Stack direction="column" gap={3}>
        <Typography textAlign="start">
          Permettre au bénéficiaire d’aller plus loin ?
        </Typography>

        <Stack direction="row" alignItems="center" gap={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={factSheet?.goFurther}
                  placeholder="Oui/Non"
                  onChange={(e) =>
                    updateFactSheet("goFurther")(e.target.checked)
                  }
                />
              }
              labelPlacement="end"
              label={
                <Typography fontWeight="bold">
                  {factSheet?.goFurther ? "Oui" : "Non"}
                </Typography>
              }
            />
            {errors?.goFurther && (
              <FormHelperText style={formHelperStyle}>
                {errors?.goFurther}
              </FormHelperText>
            )}
          </FormGroup>

          {factSheet?.goFurther && (
            <TextField
              fullWidth
              minRows={3}
              type="text"
              color="primary"
              placeholder="Aller plus loin ?"
              helperText={errors?.goFurtherLabel}
              error={!!errors?.goFurtherLabel}
              label="Label du bouton"
              onChange={(e) =>
                updateFactSheet("goFurtherLabel")(e.target.value)
              }
              value={factSheet?.goFurtherLabel}
              FormHelperTextProps={{ style: formHelperStyle }}
            />
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};
