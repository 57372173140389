import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import {
  PaperContainer,
  ResponsiveContainer,
} from "../../../Components";
import { DatatableSearchProvider } from "../../../Providers";
import { useDatatableSearch } from "../../../Hooks";
import APIAxios, { APIRoutes } from "../../../API/api.axios";
import { NewHeader } from "../../../Components/Molecules/new-title.component";
import SheetsBlackIcon from "../../../Assets/sheets_black.svg";
import Search from "../../../Assets/search.svg";
import { SelectOption } from "../../../Components/Molecules/organism-search.molecule";
import { FilterPopup } from "../../../Components/Molecules/filter-popup.molecule";
import { FactSheetsDataTable } from "./components/fact-sheets.table";
import { GetFactSheetsAdminDto } from "../../../Types/fact-sheets.types";

const NewFactSheetHeader = () => {
  const { search, updateSearch } = useDatatableSearch();
  const navigate = useNavigate();

  return (
    <>
      <NewHeader title="Fiches d'information" image={SheetsBlackIcon} />
      <Grid container direction="row" alignItems="center" gap={2}>
        <Grid item xs>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => updateSearch(e.target.value)}
            placeholder="Rechercher une fiche"
            InputProps={{
              startAdornment: <img src={Search} alt="Recherche" />,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => navigate("create")}
            startIcon={<Add />}
          >
            Créer une fiche
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const MyFactSheets = () => {
  const [selectedFactSheet, setSelectedFactSheet] = useState<
    SelectOption | undefined
  >();
  const [filterPopupOpen, setFilterPopUpOpen] = useState(false);

  const getFactSheets = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({ ...APIRoutes.GETFactSheets(1, q, 50) }).then(
      (res) => {
        if (res.data) {
          return res.data.data.map((it: GetFactSheetsAdminDto) => ({
            label: it.title,
            value: it.id,
            data: it,
          }));
        }
      }
    );
  };

  return (
    <>
      {filterPopupOpen && (
        <FilterPopup
          title="Chercher une fiche"
          placeholder="Titre de la fiche"
          selectedOption={selectedFactSheet}
          handleSelect={(value) => {
            setSelectedFactSheet(value);
            setFilterPopUpOpen(false);
          }}
          handleClose={() => setFilterPopUpOpen(false)}
          getOptions={getFactSheets}
        />
      )}
      <ResponsiveContainer>
        <DatatableSearchProvider>
          <Stack width="100%" height="100%" gap={3}>
            <NewFactSheetHeader />

            <PaperContainer>
              <FactSheetsDataTable />
            </PaperContainer>
          </Stack>
        </DatatableSearchProvider>
        <Outlet></Outlet>
      </ResponsiveContainer>
    </>
  );
};
