import * as Yup from "yup";

export const samiContactValidation = Yup.object().shape(
  {
    firstname: Yup.string(),
    lastname: Yup.string(),
    factSheetTitle: Yup.string(),
    message: Yup.string()
      .min(1, "Ce champ ne peut pas être vide.")
      .max(800, "Ce champ ne peut pas excéder 800 caractères."),
    from: Yup.string()
      .required("Un email est requis")
      .email("Le format de l'e-mail est incorrecte"),
  },
  [["searchUser", "email"]]
);
