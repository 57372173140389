/* eslint-disable @typescript-eslint/no-empty-function */
import AbstractProfileService from '../base.profile.service';

import { TBeneficiaryProfile, TConsultantProfile } from '../../../Types';
import {
  setDoc,
  Firestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  deleteDoc,
} from 'firebase/firestore';
import { db, globalGetDocRef } from '../../../Configuration/firebase.config';
import { DatabaseCollection } from '../../../Configuration/firebase.config';

class BeneficiaryProfileService extends AbstractProfileService<TBeneficiaryProfile> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(db: Firestore, dbCollection: DatabaseCollection) {
    super(db, dbCollection);
  }

  public async create(uid: string): Promise<void> {
    const beneficiaryProfile: TBeneficiaryProfile = {
      codeAPE: '',
      contractType: '',
      isWorkArrest: false,
      dateWorkArrest: '',
      typeWorkArrest: '',
      formation: '',
      isBOE: false,
      language: '',
      languageLevel: '',
      levelSchool: '',
      postOccupied: '',
      status: '',
      statusBOE: '',
      departmentLocation: '',
    };

    try {
      await setDoc(this.getDocRef(uid), beneficiaryProfile);
    } catch (error) {
      throw error;
    }
  }

  public async handleInvitation(uid: string, invitationId: string, accept: boolean) {
    // Get the invited userid
    const userDocRef = this.getDocRef(uid);
    // Get the invited invitation by id
    const invitationDocRef = doc(this._db, userDocRef.path, '/invitations', `/${invitationId}`);
    // Get the invitation document
    const invitationDoc = await getDoc(invitationDocRef);
    if (invitationDoc.exists()) {
      const data = invitationDoc.data();
      const { from } = data;
      //Get a reference to who is inviting uid
      const invitantDocRef = globalGetDocRef<TConsultantProfile>(`consultant/${from}`);
      // If i accept the invitation, update my followingConsultant and update the invitant followedBeneficiaries array
      if (accept) {
        await updateDoc(invitantDocRef, {
          followedBeneficiaries: arrayUnion(uid),
        });
        await updateDoc(userDocRef, {
          followingConsultants: arrayUnion(from),
        });
      }
      // At the end of the process delete the invitation
      await deleteDoc(invitationDocRef);
    }
  }

  //TODO update to use invitationType here too
  // public async createInvitation(uid: string, invitationPayload: TInvitationQuery) {
  //   try {
  //     const { userMail, searchUser, senderFullName, message } = invitationPayload;
  //     if (userMail?.length) {
  //       await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/beneficiary/invite`, {
  //         id: uid,
  //         email: invitationPayload.userMail,
  //         message,
  //       });
  //     } else if (!!searchUser) {
  //       if (searchUser.email?.length) {
  //         await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/beneficiary/invite`, {
  //           id: uid,
  //           email: searchUser.email,
  //           message,
  //         });
  //       } else {
  //         const invitedDocRef = globalGetDocRef<TConsultantProfile>(`consultant/${searchUser.uid}`);
  //         const invitedCollectionRef = collection(
  //           this._db,
  //           invitedDocRef.path,
  //           '/invitations'
  //         ) as CollectionReference<TInvitation>;
  //         await addDoc(invitedCollectionRef, {
  //           from: uid,
  //           message: message,
  //           user: senderFullName,
  //           invitantRole: 'beneficiary'
  //         })
  //       }
  //     }
  //   } catch (error) {
  //     throw Error("L'invitation n'a pas abouti.");
  //   }
  // }

  // getConsultants = (benefId: string): Observable<GetConsultantDto[]> => {
  //   return from(APIAxios({...APIRoutes.GETConsultants()})).pipe(
  //     catchError((err: AxiosError) => {
  //       const data = err.response?.data;
  //       switch (data.message) {
  //         default:
  //           throw new SnackError("Une erreur est survenue. Veuillez réessayer plus tard.", "error");
  //       }
  //     })
  //   )
  // }

}

export const beneficiaryProfileService = new BeneficiaryProfileService(db, 'beneficiary');
