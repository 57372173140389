import AbstractProfileService from './base.profile.service';
import { TUserProfile, TAccountCreateData, TUserSearch } from '../../Types';
import { setDoc, Firestore, collection, where, getDocs, query } from 'firebase/firestore';
import { db } from '../../Configuration/firebase.config';
import { DatabaseCollection } from '../../Configuration/firebase.config';
class UserProfileService extends AbstractProfileService<TUserProfile> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(db: Firestore, dbCollection: DatabaseCollection) {
    super(db, dbCollection);
  }

  public async create(uid: string, createUserData: Required<TAccountCreateData>): Promise<void> {
    const userProfile: TUserProfile = {
      email: createUserData?.email ? createUserData.email : '',
      gender: createUserData?.gender ? createUserData.gender : 'male',
      birthday: '',
      firstName: createUserData?.firstName?.toLowerCase(),
      lastName: createUserData?.lastName?.toLowerCase(),
      city: '',
      phoneNumber: null,
      postcode: '',
      address: '',
    };
    try {
      const docRef = this.getDocRef(uid);
      await setDoc(docRef, userProfile);
    } catch (error) {
      throw error;
    }
  }

  public async searchByName(search: string, searcherIsConsultant: boolean): Promise<TUserSearch[]> {
    const collectionRef = collection(this._db, this._dbCollection);
    const q = query(
      collectionRef,
      where('isConsultant', '==', !searcherIsConsultant),
      where('isInvited', '==', false),
      where('isDeleted', '==', false),
      where('lastname', '>=', search),
      where('lastname', '<=', search + '\uf8ff'),
    );
    return getDocs(q).then((snapshot) => {
      return snapshot.docs.map((d) => ({
        email: d.data().email,
        firstName: d.data().firstName,
        lastName: d.data().lastName,
        uid: d.id,
      })) as TUserSearch[];
    });
  }
}

export const userProfileService = new UserProfileService(db, 'profile');
