import { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TUserProfile } from '../../../../../Types';
import TextField from '@mui/material/TextField';
import CreateImage from '../../../../../Assets/create.png';
import { Hidden } from '@mui/material';
import { personalValidation } from './personalInformations.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IUserRegistrationProps } from '..';
import Button from '@mui/material/Button';
import { useStateMachine } from 'little-state-machine';
import { useError, usePopup } from '../../../../../Hooks';
import APIAxios, { APIRoutes } from '../../../../../API/api.axios';
import { useSnackbar } from 'notistack';
import { updateUserState } from '../../../../../Stores';

export const PersonalInformations = ({ userRole, handleNextStep }: IUserRegistrationProps) => {
  const {
    state: {
      user: {
        beneficiary,
        consultant,
        organism
      },
    },
    actions,
  } = useStateMachine({ updateUserState });
  const { closePopup } = usePopup('RegistrationPopup');
  const [loading, setLoading] = useState<boolean>(false);
  const { register, formState, handleSubmit } = useForm<Partial<TUserProfile>>({
    resolver: yupResolver(personalValidation),
    defaultValues: {
      firstName: beneficiary?.firstName || consultant?.firstName || organism?.firstName,
      lastName: beneficiary?.lastName || consultant?.lastName || organism?.lastName,
      birthday: beneficiary?.birthday,
      address: beneficiary?.address || consultant?.address,
      postcode: beneficiary?.postcode || consultant?.postcode,
      city: beneficiary?.city || consultant?.city,
      phoneNumber: (beneficiary?.phoneNumber || consultant?.phoneNumber) === '' ? null : beneficiary?.phoneNumber || consultant?.phoneNumber
    },
    mode: 'onChange',
  });

  const { isValid } = formState;
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useError();

  const onSubmit = useCallback(
    async (data: Partial<TUserProfile>) => {
      if (beneficiary) {
        setLoading(true);
        try {
          const userData = {...data,
            phoneNumber: !!!data.phoneNumber ? null : data.phoneNumber}
          const res = await APIAxios({...APIRoutes.PATCHBeneficiaries(), data: userData})
          setLoading(false);

          if (res.data) {
            actions.updateUserState({beneficiary: res.data})
            handleNextStep();
          } else {
            enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
          }
        } catch (err: any) {
          err.response.data.message.forEach((it: string) => {
            switch (it) {
              case 'postcode must be a number conforming to the specified constraints':
                addError({message: 'Veuillez rentrer un code postal valide.'})
                break
              case 'phoneNumber must be a valid phone number':
                addError({message: 'Veuillez rentrer un numéro de téléphone valide.'})
                break
              default:
                enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
                break
            }
          })
        }
        setLoading(false);
      } else if (consultant) {
        try {
          const userData = {...data,
            phoneNumber: !!!data.phoneNumber ? null : data.phoneNumber}
          const res = await APIAxios({...APIRoutes.PATCHConsultants(), data: userData})
          setLoading(false);
          if (res.data) {
            actions.updateUserState({consultant: res.data});
            handleNextStep()
          } else {
            enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
          }
        } catch (err: any) {
          err.response.data.message.forEach((it: string) => {
            switch (it) {
              case 'postcode must be a number conforming to the specified constraints':
                addError({message: 'Veuillez rentrer un code postal valide.'})
                break
              case 'phoneNumber must be a valid phone number':
                addError({message: 'Veuillez rentrer un numéro de téléphone valide.'})
                break
              default:
                enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
                break
            }
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, beneficiary, consultant, enqueueSnackbar, handleNextStep],
  );

  return (
    <>
      <Grid container justifyContent="flex-start" textAlign="start">
        <Grid item xs={12} marginBottom={2} alignContent='center' display='flex' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h2">
            Complétez votre fiche {`${userRole === 'beneficiary' ? 'bénéficiaire' : 'consultant'}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} container direction="column" gap={2}>
          <Typography variant="h3">Informations personnelles</Typography>
          <TextField
            {...register('firstName')}
            error={!!formState.errors.firstName}
            helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
            fullWidth
            required
            type="text"
            color="primary"
            placeholder="Prénom"
            label="Prénom"
          />
          <TextField
            {...register('lastName')}
            error={!!formState.errors.lastName}
            helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
            fullWidth
            required
            type="text"
            color="primary"
            placeholder="Nom de famille"
            label="Nom de famille"
          />
          {beneficiary && (
            <TextField
              {...register('birthday')}
              fullWidth
              error={!!formState.errors.birthday}
              value={beneficiary.birthday && new Date(beneficiary.birthday).toISOString().split('T')[0]}
              helperText={formState.errors.birthday ? formState.errors.birthday.message : ''}
              required
              autoFocus
              type="date"
              color="primary"
              placeholder="Date de naissance"
              label="Date de naissance"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <TextField
            {...register('address')}
            error={!!formState.errors.address}
            helperText={formState.errors.address ? formState.errors.address.message : ''}
            fullWidth
            required
            type="text"
            color="primary"
            placeholder="Adresse"
            label="Adresse"
          />
          <TextField
            {...register('postcode')}
            error={!!formState.errors.postcode}
            helperText={formState.errors.postcode ? formState.errors.postcode.message : ''}
            fullWidth
            required
            type="text"
            color="primary"
            placeholder="Code postal"
            label="Code postal"
          />
          <TextField
            {...register('city')}
            error={!!formState.errors.city}
            helperText={formState.errors.city ? formState.errors.city.message : ''}
            fullWidth
            required
            type="text"
            color="primary"
            placeholder="Ville"
            label="Ville"
          />
          <TextField
            {...register('phoneNumber')}
            error={!!formState.errors.phoneNumber}
            helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
            fullWidth
            type="text"
            required
            color="primary"
            placeholder="+33 06 00 00 00 00"
            label="Téléphone"
          />
        </Grid>
        <Hidden mdDown>
          <Grid item container xs={6} justifyContent="flex-end">
            <img alt='' src={CreateImage} style={{ display: 'flex', margin: 'auto 0' }}></img>
          </Grid>
        </Hidden>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end" gap={1}>
        <Button variant="outlined" color="secondary" onClick={closePopup}>
          Plus tard
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isValid || loading}>
          {loading ? 'Patientez' : 'Suivant'}
        </Button>
      </Grid>
    </>
  );
};
