import { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useStateMachine } from "little-state-machine";
import { usePopup } from "../../../../Hooks";
import { Controller, useForm } from "react-hook-form";
import { FactSheetContactDto } from "../../../../Types";
import { yupResolver } from "@hookform/resolvers/yup";

import { Popup } from "../../../../Theme";
import { Success } from "../../../Molecules";
import { samiContactValidation } from "./sami-contact.validation";
import APIAxios, { APIRoutes } from "../../../../API/api.axios";
import { useSnackbar } from "notistack";

interface SamiContactProps {
  factSheetTitle: string;
}

export const SamiContact = ({ factSheetTitle }: SamiContactProps) => {
  const {
    state: {
      user: { beneficiary },
    },
  } = useStateMachine();
  const { enqueueSnackbar } = useSnackbar();
  const { closePopup } = usePopup("SamiContactPopup");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { formState, control, handleSubmit } = useForm<FactSheetContactDto>({
    resolver: yupResolver(samiContactValidation),
    mode: "onChange",
    defaultValues: {
      firstname: beneficiary?.firstName || "",
      lastname: beneficiary?.lastName || "",
      factSheetTitle,
      from: beneficiary?.email || "",
      message: "",
    },
  });

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const onInvitationSubmit = async (data: FactSheetContactDto) => {
    setIsLoading(true);

    try {
      await APIAxios({ ...APIRoutes.POSTFactSheetContact(), data });

      setSuccess(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Une erreur est survenue lors de l'envoi du contact", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <Popup open={true}>
        <Success title="Le message a été envoyé" popupId="SamiContactPopup" />;
      </Popup>
    );
  }

  return (
    <Grid
      container
      padding={isSm ? theme.spacing(2) : theme.spacing(6)}
      justifyContent="center"
    >
      <Grid
        container
        justifyContent="space-between"
        marginBottom={4}
        wrap="nowrap"
      >
        <Typography variant="h2">Contacter l'équipe Sam.i</Typography>

        <Button variant="icon" color="primary" onClick={closePopup}>
          <CloseRoundedIcon />
        </Button>
      </Grid>

      <Grid container item xs={12} gap={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Personnalisez votre message</Typography>
        </Grid>

        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState?.errors.message}
              helperText={
                formState?.errors.message
                  ? formState?.errors.message.message
                  : ""
              }
              fullWidth
              placeholder="Veuillez saisir votre message ici"
              label="Message"
              type="text"
              multiline
              minRows={8}
            />
          )}
        />
      </Grid>

      <Grid item container xs={12} justifyContent="center" marginTop={4}>
        <Button
          variant="contained"
          disabled={!formState?.isValid || isLoading}
          onClick={handleSubmit(onInvitationSubmit)}
        >
          Envoyer
        </Button>
      </Grid>
    </Grid>
  );
};
