import * as Yup from 'yup';

import { transformError } from '../../../Utils/yup.utils';
import { BothBooleanEnum, CreateFactSheetDto } from '../../../Types/fact-sheets.types';

export const defaultValues = {
  title: "",
  description: "",
  videoLink: "",
  isWorkArrest: BothBooleanEnum.both,
  isBOE: BothBooleanEnum.both,
  daysCount: 0,
  actionTypes: [],
}

export const factSheetFirstStepValidation = (factSheet: Partial<CreateFactSheetDto>) => {
  try {
    const schema =  Yup.object().shape({
      title: Yup.string().required('Ce champ est requis').max(250).default(''),
      description: Yup.string().required('Ce champ est requis').default(''),
      videoLink: Yup.string().required('Ce champ est requis').default(''),
    })

    schema.validateSync(factSheet, { abortEarly: false });

    return {};
  } catch (err) {
    return transformError(err as any);
  }
}

export const factSheetSecondStepValidation = (factSheet: Partial<CreateFactSheetDto>) => {
  try {
    const schema =  Yup.object().shape({
      isWorkArrest: Yup.number().required('Ce champ est requis').default(BothBooleanEnum.both),
      isBOE: Yup.number().required('Ce champ est requis').default(BothBooleanEnum.both),
      daysCount: Yup.number().required('Ce champ est requis').default(0),
      actionTypes: Yup.array().of(Yup.string()).optional().default([]),
      goFurther: Yup.bool().required('Ce champ est requis').default(false),
      goFurtherLabel: Yup.string().when('goFurther', {
        is: true,
        then: Yup.string(),
      })
    })

    schema.validateSync(factSheet, { abortEarly: false });

    return {};
  } catch (err) {
    return transformError(err as any);
  }
}
