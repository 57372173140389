import { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ReactPlayer from "react-player";

import APIAxios, { APIRoutes } from "../../../API/api.axios";
import { GetFactSheetByIdDto } from "../../../Types/fact-sheets.types";
import { FactSheetHeader } from "./header.fact-sheet";

export const FactSheetDetail = () => {
  const { factSheetId } = useParams();
  const [factSheet, setFactSheet] = useState<GetFactSheetByIdDto>();
  const [isFetching, setIsFetching] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerLoading, setIsPlayerLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getFactSheet = async () => {
    if (!factSheetId) return;

    setIsFetching(true);

    try {
      const res = await APIAxios({
        ...APIRoutes.GETFactSheetById(factSheetId),
      });

      if (res.data) {
        setFactSheet(res.data);
      }
    } catch (error) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de la fiche",
        { variant: "error" }
      );
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getFactSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factSheetId]);

  if (isFetching) {
    return (
      <Stack>
        <Typography>Chargement...</Typography>
      </Stack>
    );
  }

  const {
    title,
    description,
    videoLink,
    actionTypes,
    goFurther,
    goFurtherLabel,
  } = factSheet || {};

  return (
    <Stack gap={3}>
      <FactSheetHeader
        title={title}
        actionTypes={actionTypes}
        factSheetTitle={title}
        goFurther={goFurther}
        goFurtherLabel={goFurtherLabel}
      />
      {isPlayerLoading ? (
        <CircularProgress color="primary" style={{ alignSelf: "center" }} />
      ) : (
        <ReactPlayer
          url={videoLink}
          width="100%"
          controls
          playing={isPlaying}
          onError={(e) => {
            enqueueSnackbar(
              "La vidéo n'a pas pu être chargée, vérifiez la validité du lien et réessayez",
              {
                variant: "error",
              }
            );

            console.warn(e);
          }}
          onReady={() => setIsPlayerLoading(false)}
          onStart={() => setIsPlayerLoading(false)}
          onPause={() => setIsPlaying(false)}
          onEnded={() => {
            setIsPlaying(true);
            setTimeout(() => setIsPlaying(false), 100);
          }}
          config={{
            vimeo: {
              playerOptions: {
                controls: true,
                title: false,
                byline: false,
                speed: false,
                transparent: true,
              },
            },
          }}
          style={{ alignSelf: "start" }}
        />
      )}
      <Typography
        variant="h3"
        color="GrayText"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {description || "Cette fiche n'a pas de description"}
      </Typography>{" "}
    </Stack>
  );
};
