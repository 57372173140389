import AbstractDataTableService from '../data-table.base.service';
import { GetActionLightDto } from '../../../Types/actions.type';

export interface IMyActionsRow {
  /* the id of the action */
  id: string;
  createdAt?: Date;
  beneficiary: {
    name: string;
    isWorkArrest: boolean;
    beneficiaryId: string;
  };
  type: string;
  description: string;
  status: string;
}

export class MyActionsDataTableService extends AbstractDataTableService<GetActionLightDto, IMyActionsRow> {
  public filteredCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pageSize: number) {
    super(pageSize);
  }

  protected buildRow(action: GetActionLightDto): IMyActionsRow {
    return {
      id: action.id,
      type: action.type,
      beneficiary: {
        beneficiaryId: action.beneficiary.id,
        name: action?.beneficiary ? action?.beneficiary?.lastName?.toUpperCase() + ' ' + action?.beneficiary?.firstName : '-',
        isWorkArrest: action.isWorkArrest || false,
      },
      createdAt: action?.createdAt,
      status: action?.status || '-',
      description: action?.description || '-',
    };
  }
}

export const myActionsDataTableService = new MyActionsDataTableService(10);
