import { GetOrganismAdminDto } from "../../../Types";
import AbstractDataTableService from "../data-table.base.service";


export interface OrganismRow {
  id: string;
  profile: {
    photoUrl: string;
    name: string;
  };
  consultantCount: number;
  beneficiaryCount: number;
  actionCount: number;
  isDeleted: boolean;
}

class OrganismsDataTableService extends AbstractDataTableService<GetOrganismAdminDto, OrganismRow> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pageSize: number) {
    super(pageSize)
  }

  protected buildRow(organism: GetOrganismAdminDto): OrganismRow {
    return {
      id: organism.id,
      profile: {
        photoUrl: organism.name,
        name: organism.name,
      },
      consultantCount: organism.consultantCount,
      beneficiaryCount: organism.beneficiaryCount,
      actionCount: organism.actionCount,
      isDeleted: organism.isDeleted || false,
    }
  }
}

export const organismsDataTableService = new OrganismsDataTableService(10);