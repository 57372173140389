import Typography from "@mui/material/Typography";

import { Header, SamiContact } from "../../../Components";
import { Button, Grid, Stack, useMediaQuery } from "@mui/material";
import { ActionType } from "../../../Types/actions.type";
import theme, { Popup } from "../../../Theme";
import { usePopup } from "../../../Hooks";

interface IFactSheetHeaderProps {
  title?: string;
  actionTypes?: string[];
  factSheetTitle?: string;
  goFurther?: boolean;
  goFurtherLabel?: string;
}

export const FactSheetHeader = ({
  title = "",
  actionTypes = [],
  factSheetTitle = "",
  goFurther = false,
  goFurtherLabel = "",
}: IFactSheetHeaderProps) => {
  const {
    popupOpen: isSamiContactPopupOpen,
    closePopup: closeSamiContactPopup,
    openPopup,
  } = usePopup("SamiContactPopup");
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleGoFurtherClick = () => {
    openPopup();
  };

  return (
    <>
      <Header
        title={
          <Stack direction="column" gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h1" component="h1" noWrap maxWidth="50vw">
                {title || "Fiche d'information"}
              </Typography>

              {goFurther && (
                <Button
                  onClick={handleGoFurtherClick}
                  variant="contained"
                  color="primary"
                >
                  {goFurtherLabel || "Aller plus loin ?"}
                </Button>
              )}
            </Stack>
            <Grid container flexWrap="wrap" alignItems="end" gap={2}>
              {actionTypes.map((actionType, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: 6,
                    paddingLeft: 12,
                    paddingRight: 12,
                    borderRadius: 16,
                  }}
                >
                  <Typography variant="h4" color="white">
                    {ActionType.label(actionType)}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Stack>
        }
      />

      <Popup
        fullScreen={fullscreen}
        open={isSamiContactPopupOpen()}
        maxWidth="md"
        onClose={closeSamiContactPopup}
        transitionDuration={0}
      >
        <SamiContact factSheetTitle={factSheetTitle} />
      </Popup>
    </>
  );
};
