/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useEffect, useState, useCallback } from 'react';
import { useStateMachine } from 'little-state-machine';
import { GetBeneficiaryDto, GetConsultantDto, GetOrganismDto, TContainer } from '../Types';
import { CONSTANTS } from '../Constants';

interface IProfileCompletionContext {
  completion: number | null;
}

export const ProfileCompletionContext = createContext<IProfileCompletionContext>({
  completion: null,
});

export const ProfileCompletionProvider = ({ children }: TContainer) => {
  const [completion, setCompletion] = useState<number | null>(null);
  const {
    state: {
      // user: { profile, beneficiaryProfile, consultantProfile, uid },
      user: { beneficiary, consultant, organism },
    },
  } = useStateMachine();

  const computeCompletion = useCallback(
    (
      beneficiary?: Partial<GetBeneficiaryDto>,
      consultant?: Partial<GetConsultantDto>,
      organism?: Partial<GetOrganismDto>
    ) => {
      let completed = 0;
      let total = 0;
      if (consultant !== null) {
        const consultantFieldsCompleted = CONSTANTS.ConsultantProfileRequiredFields.filter(
          (consultantProfileKey) => consultant && (consultant[consultantProfileKey] !== null && consultant[consultantProfileKey] !== ''),
        ).length;
        total = CONSTANTS.ConsultantProfileRequiredFields.length;
        completed = consultantFieldsCompleted;
      } else if (beneficiary !== null) {
        const beneficiaryFieldsCompleted = CONSTANTS.BeneficiaryProfileRequiredFields2.filter(
          (beneficiaryKey) => beneficiary && (beneficiary[beneficiaryKey] !== null && beneficiary[beneficiaryKey] !== ''),
          ).length;
        total = CONSTANTS.BeneficiaryProfileRequiredFields.length;
        completed = beneficiaryFieldsCompleted;
      } else {
        const organismFieldsCompleted = CONSTANTS.OrganismProfileRequiredFields.filter(
          (organismKey) => organism && (organism[organismKey] !== '' && organism[organismKey] !== null),
        ).length;
        total = CONSTANTS.OrganismProfileRequiredFields.length
        completed = organismFieldsCompleted;
      }
      const completedPercentage = (completed / total) * 100;
      setCompletion(Math.round(completedPercentage));
    },
    [],
  );
  
  useEffect(() => {
      computeCompletion(beneficiary, consultant, organism);
  }, [beneficiary, consultant, organism, computeCompletion, completion]);

  const contextValue = {
    completion,
  };

  return <ProfileCompletionContext.Provider value={contextValue}>{children}</ProfileCompletionContext.Provider>;
};
