import { Typography } from "@mui/material";

export const Copyright = () => {
  const today = new Date().getFullYear();
  return (
    <footer
      style={{
        position: "absolute",
        height: "20px",
        textAlign: "center",
        width: "100%",
        bottom: "1rem",
      }}
    >
      <Typography
        sx={{
          height: "20px",
          textAlign: "center",
          width: "100%",
        }}
        variant="subtitle2"
        component="div"
        textAlign="center"
      >
        &copy; Copyright {today} -Tous droits réservés- Tech for Work
      </Typography>
    </footer>
  );
};
