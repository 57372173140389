import { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, TextField } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ProfileAvatar, ResponsiveContainer } from '../../../Components';
import { DatatableSearchProvider } from '../../../Providers';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { BeneficiaryRow, consultantsBeneficiariesDataTableService } from '../../../Services';
import { GetBeneficiaryListDto, StatusBoe } from '../../../Types';
import { CustomPagination } from '../../../Theme';

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: 'Nom / Prénom',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Nom / Prénom</Typography>,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        <ProfileAvatar size={37} />
        <Typography fontSize="12px">
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "statusBOE",
    headerName: 'Situation',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Situation</Typography>,
    renderCell: (params: GridCellParams) => (
      <Typography fontSize="12px">
        {params.value ? StatusBoe.label(params.value) : '-'}
      </Typography>
    ),
  },
  {
    field: "consultant",
    headerName: 'Consultant(s)',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Consultant(s)</Typography>,
    renderCell: (params: GridCellParams) => (
      <Typography fontSize="12px">
        {params.value}
      </Typography>
    ),
  },
];


interface BeneficiaryTableProps {
  consultantId?: string;
}

export const ConsultantsBeneficiariesTable = (props: BeneficiaryTableProps) => {

  const { consultantId } = props;

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [benef, setBenef] = useState<GetBeneficiaryListDto | undefined>(undefined);
  const [data, setData] = useState<BeneficiaryRow[] | undefined>(undefined);

  const fetchBeneficiaries = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETBeneficiaries(page, search, consultantId)})
      if (res.data) {
        setBenef(res.data);

        const rows = consultantsBeneficiariesDataTableService.buildRows(res.data.data);
        setData(rows);
      }
    } catch (err) {
    }
  }, [page, search, consultantId]);

  useEffect(() => {
    fetchBeneficiaries()
  }, [fetchBeneficiaries]);

  if (!data) {
    return null
  }

  return (
    <ResponsiveContainer>
      <DatatableSearchProvider>
      <TextField
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        label="Rechercher un(e) bénéficiaire"
        placeholder="Rechercher un(e) bénéficiaire"
        />
          <DataGrid
            rows={data}
            columns={columns}
            autoHeight
            disableSelectionOnClick
            onPageChange={setPage}
            pageSize={benef?.meta.take}
            rowCount={benef?.meta.itemCount}
            components={{
              Pagination: CustomPagination
            }}
            paginationMode='server'
            density='standard'
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
              '&.MuiDataGrid-root': {
                border: 'none',
              },
              '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
                marginTop: '32px !important',
                backgroundColor: 'white !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white !important',
                height: '35px !important',
                maxHeight: '35px !important',
                minHeight: '35px !important',
                fontSize: '10px',
                fontWeight: '500',
              },
              '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
                border: '0px solid transparent'
              },
              '.& MuiDataGrid-columnHeaders': {
                fontSize: '10px',
                fontWeight: '500',
              },
              '&.MuiDataGrid-root *:focus-within': {
                outline: 'none',
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
                overflow: 'hidden',
                whiteSpace: 'normal',
                color: 'rgb(155, 159, 164)',
                footWeight: '500',
                display: 'flex',
                justifyContent: 'start',
                fontSize: '10px',
                textAlign: 'start',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell': {
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'start',
                whiteSpace: 'normal',
                textAlign: 'start',
                borderTop: '1px solid rgb(233, 233, 233)',
                borderBottom: '0px'
              },
            }}
          />
      </DatatableSearchProvider>
    </ResponsiveContainer>
  )
}