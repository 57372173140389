import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useStateMachine } from "little-state-machine";
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
    BeneficiaryStatus,
    ContractEnum,
    Departments,
    GetBeneficiaryDto,
    LanguageLevel,
    LevelSchool,
    StatusBoe,
    TypeWorkArrest
} from '../../../../../Types';
import { professionalValidationBeneficiary } from './professionalInformations.validation';
import APIAxios, { APIRoutes } from '../../../../../API/api.axios';
import { ApeSearchField } from "../../../../Molecules";

type HelpingBeneficiaryProps = {
    handleNextStep: () => void;
    beneficiary?: GetBeneficiaryDto;
    handleClose: () => void;
};

export const ProfessionalInformationsHelpingBeneficiary = ({ beneficiary, handleClose }: HelpingBeneficiaryProps) => {
    const {
        state: {
          user: { id },
        },
    } = useStateMachine();

    const formMethods = useForm<Partial<GetBeneficiaryDto>>({
      resolver: yupResolver(professionalValidationBeneficiary),
      mode: 'onChange'
    });
    const { register, formState, control, watch, handleSubmit } = formMethods;
    const isBOE = watch('isBOE', beneficiary?.isBOE || false);
    const isWorkArrest = watch('isWorkArrest', beneficiary?.isWorkArrest || false);
    const { isValid } = formState;
    const { enqueueSnackbar } = useSnackbar();
  
    const onSubmit = useCallback(
      async (data: Partial<GetBeneficiaryDto>) => {
        if (beneficiary) {
          try {
            const res = await APIAxios({...APIRoutes.PATCHBeneficiaryHelp(beneficiary.id), data: { ...data, isHelpNeeded: false }})

            if (res.status === 200) {
              enqueueSnackbar('Les informations professionnelles du bénéficiaire ont bien été enregistrées', { variant: 'success' });
              handleClose();
              setTimeout(() => {
                window.location.reload();
              }, 1500)
            } else {
              enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', { variant: 'error' })
            }
          } catch (err) {
            enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', { variant: 'error' })
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id],
    );
  
    const convert2bool = (s: string) => {
      if (s.toLowerCase() === 'true') {
        return true;
      } else if (s.toLowerCase() === 'false') {
        return false;
      }
      console.error('unknown casted type');
      return false;
    };

    const convertDate = (date: string | undefined) => {
      if (!date) return ''

      return new Date(date).toISOString().split('T')[0]
    }
  
    return (
      <>
        <Grid container justifyContent="space-between" textAlign="start" gap={2}>
          <Grid container flexDirection="column" item xs={12} marginBottom={2}>
            <Button style={{ alignSelf: "end" }} variant="icon" color="primary" onClick={handleClose.bind(this)}>
              <CloseRoundedIcon />
            </Button>
            <Typography variant="h2">
              {`Complétez la fiche bénéficiaire de ${beneficiary?.firstName} ${beneficiary?.lastName}`}
            </Typography>
          </Grid>
  
          <Grid item xs={12} md={5} container gap={2}>
            <Typography variant="h3">Informations professionnelles</Typography>
            <Controller
              name="status"
              control={control}
              defaultValue={beneficiary?.status}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!formState.errors.status}
                  helperText={formState.errors.status ? formState.errors.status.message : ''}
                  select
                  fullWidth
                  required
                  autoFocus
                  color="primary"
                  placeholder="Statut"
                  label="Statut"
                >
                  {BeneficiaryStatus.beneficiaryStatuses.map((it) => (
                    <MenuItem value={it} key={it}>
                      {BeneficiaryStatus.label(it)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              {...register('job')}
              fullWidth
              error={!!formState.errors.job}
              helperText={formState.errors.job ? formState.errors.job.message : ''}
              required
              defaultValue={beneficiary?.job || ''}
              type="text"
              color="primary"
              placeholder="Poste occupé"
              label="Poste occupé"
            />
            <FormProvider {...formMethods}>
              <Controller
                control={control}
                name="ape"
                defaultValue={beneficiary?.ape}
                render={({ field: { value, onChange } }) => <ApeSearchField value={value} onChange={onChange} />}
              />
            </FormProvider>
            <Controller
              control={control}
              defaultValue={beneficiary?.contract}
              name="contract"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!formState.errors.contract}
                  helperText={formState.errors.contract ? formState.errors.contract.message : ''}
                  select
                  required
                  type="text"
                  color="primary"
                  placeholder="Type de contrat"
                  label="Type de contrat"
                >
                  {ContractEnum.contracts.map((contractType) => (
                    <MenuItem value={contractType} key={contractType}>
                      {ContractEnum.label(contractType)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              defaultValue={beneficiary?.departmentLocation}
              name="departmentLocation"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  error={!!formState.errors.departmentLocation}
                  helperText={formState.errors.departmentLocation ? formState.errors.departmentLocation.message : ''}
                  type="text"
                  color="primary"
                  placeholder="Département du lieu de travail"
                  label="Département du lieu de travail"
                >
                  {Departments.AllDepartments.map((dep) => (
                    <MenuItem value={dep} key={dep}>
                      {dep + ' ' + Departments.label(dep)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              defaultValue={
                beneficiary?.levelSchool
              }
              name="levelSchool"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!formState.errors.levelSchool}
                  helperText={formState.errors.levelSchool ? formState.errors.levelSchool.message : ''}
                  select
                  type="text"
                  color="primary"
                  placeholder="Niveau scolaire"
                  label="Niveau scolaire"
                >
                  {LevelSchool.levelSchools.map((levelSchool) => (
                    <MenuItem value={levelSchool} key={levelSchool}>
                      {LevelSchool.label(levelSchool)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              {...register('language')}
              error={!!formState.errors.language}
              helperText={formState.errors.language ? formState.errors.language.message : ''}
              fullWidth
              type="text"
              color="primary"
              placeholder="Langue"
              label="Langue"
              defaultValue={beneficiary?.language || ''}
            ></TextField>
            <Controller
              control={control}
              name="languageLevel"
              defaultValue={beneficiary?.languageLevel}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!formState.errors.languageLevel}
                  helperText={formState.errors.languageLevel ? formState.errors.languageLevel.message : ''}
                  select
                  type="text"
                  color="primary"
                  placeholder="Niveau de langue"
                  label="Niveau de langue"
                >
                  {LanguageLevel.languageLevels.map((it) => (
                    <MenuItem value={it} key={it}>
                      {LanguageLevel.label(it)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ borderWidth: '1px', borderRadius: '2px', borderColor: '#f1f1f1' }}
          />
          <Grid item xs={12} md={5} container direction="column" gap={4}>
            <Typography variant="h3">Situation</Typography>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <Typography fontWeight="medium" variant="body1">
                  Êtes-vous Bénéficiaire de l&apos;Obligation d&apos;emploi (BOE) ?
                </Typography>
              </Grid>
              <Controller
                name="isBOE"
                control={control}
                defaultValue={beneficiary?.isBOE || false}
                render={({ field }) => (
                  <FormControl component="fieldset" error={!!formState.errors.isBOE}>
                    <RadioGroup
                      {...field}
                      row
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(convert2bool(e.target.value));
                      }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Oui" />
                      <FormControlLabel value={false} control={<Radio />} label="Non" />
                    </RadioGroup>
                    {!!formState.errors.isBOE && <FormHelperText>{formState.errors.isBOE.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              {isBOE && (
                <Grid container gap={1}>
                  <Typography fontWeight="medium" variant="body1">
                    Précisez votre situation
                  </Typography>
                  <Controller
                    name="statusBOE"
                    defaultValue={beneficiary?.statusBOE}
                    control={control}
                    shouldUnregister={true}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!formState.errors.statusBOE}
                        helperText={formState.errors.statusBOE ? formState.errors.statusBOE.message : ''}
                        select
                        type="text"
                        color="primary"
                        placeholder="Sélectionner une situation"
                        label="Sélectionner une situation"
                      >
                        {StatusBoe.statuses.map((boe) => (
                          <MenuItem value={boe} key={boe}>
                            {StatusBoe.label(boe)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Typography fontWeight="medium" variant="body1" margin={0}>
                Êtes-vous en arrêt de travail ?
              </Typography>
              <Grid container gap={2}>
                <Controller
                  name="isWorkArrest"
                  rules={{ required: true }}
                  defaultValue={beneficiary?.isWorkArrest || false}
                  control={control}
                  render={({ field }) => (
                    <FormControl component="fieldset" error={!!formState.errors.isWorkArrest}>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value}
                        onChange={(e) => field.onChange(convert2bool(e.target.value))}
                      >
                        <FormControlLabel value={true} control={<Radio />} label="Oui" />
                        <FormControlLabel value={false} control={<Radio />} label="Non" />
                      </RadioGroup>
                      {!!formState.errors.isWorkArrest && (
                        <FormHelperText>{formState.errors.isWorkArrest.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                {isWorkArrest && (
                  <>
                    <Controller
                      name="dateWorkArrest"
                      control={control}
                      defaultValue={convertDate(beneficiary?.dateWorkArrest)}
                      shouldUnregister={true}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!formState.errors.dateWorkArrest}
                          helperText={formState.errors.dateWorkArrest ? formState.errors.dateWorkArrest.message : ''}
                          required
                          type="date"
                          color="primary"
                          placeholder="Date d'arret de travail"
                          label="Date d'arret de travail"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="typeWorkArrest"
                      defaultValue={beneficiary?.typeWorkArrest}
                      control={control}
                      shouldUnregister={true}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!formState.errors.typeWorkArrest}
                          helperText={formState.errors.typeWorkArrest ? formState.errors.typeWorkArrest.message : ''}
                          select
                          defaultValue=""
                          type="text"
                          color="primary"
                          placeholder="Type d'arret de travail"
                          label="Type d'arret de travail"
                        >
                          {TypeWorkArrest.typeWorkArrests.map((workArrestType) => (
                            <MenuItem value={workArrestType} key={workArrestType}>
                              {TypeWorkArrest.label(workArrestType)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid marginTop="auto" item direction="row" container justifyContent="space-between" gap={1}>
              <Button style={{ flexGrow: 1 }} variant="outlined" color="secondary" onClick={handleClose}>
                Annuler
              </Button>
              <Button style={{ flexGrow: 1 }} variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  