import { CircularProgress, Grid, TextField } from "@mui/material";
import ReactPlayer from "react-player";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { FactSheet } from "../../../../Types/fact-sheets.types";
import { FieldErrors } from "../../../../Utils/yup.utils";

interface IFirstStepFactSheetProps {
  factSheet?: FactSheet;
  errors: FieldErrors;
  setErrors: (errors: FieldErrors) => void;
  updateFactSheet: (name: string) => (value: string) => void;
}

export const FirstStepFactSheet = ({
  factSheet,
  errors,
  setErrors,
  updateFactSheet,
}: IFirstStepFactSheetProps) => {
  const snackbar = useSnackbar();
  const [isPlayerLoading, setIsPlayerLoading] = useState(false);

  return (
    <>
      <Grid container gap={2} direction="column">
        <Grid item container gap={2}>
          <Grid item xs>
            <TextField
              fullWidth
              type="text"
              color="primary"
              placeholder="Titre de votre fiche"
              value={factSheet?.title || ''}
              helperText={errors?.title}
              error={!!errors?.title}
              label="Titre"
              onChange={(e) => updateFactSheet("title")(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid display="flex" flexDirection="column" item container gap={2}>
          <Grid item xs>
            <TextField
              fullWidth
              type="text"
              color="primary"
              placeholder="Lien vers la vidéo Viméo"
              value={factSheet?.videoLink || ''}
              helperText={errors?.videoLink}
              error={!!errors?.videoLink}
              label="Lien vidéo"
              onChange={(e) => updateFactSheet("videoLink")(e.target.value)}
            />
          </Grid>

          {factSheet?.videoLink ? (
            <Grid item container alignItems="center" xs>
              {isPlayerLoading ? (
                <CircularProgress color="primary" style={{ alignSelf: "center" }} />
              ) : (
                <ReactPlayer
                  url={factSheet?.videoLink}
                  width="100%"
                  height="100%"
                  controls
                  onReady={() => {
                    if (errors?.videoLink) {
                      const { videoLink, ...rest } = errors;
                      setErrors({ ...rest });
                    }

                    setIsPlayerLoading(false)
                  }}
                  onError={(e) => {
                    snackbar.enqueueSnackbar(
                      "La vidéo n'a pas pu être chargée, vérifiez la validité du lien et réessayez",
                      {
                        variant: "error",
                      }
                    );

                    console.warn(e);
                    setErrors({
                      ...errors,
                      videoLink: "La vidéo n'a pas pu être chargée",
                    });
                  }}
                  onBufferEnd={() => setIsPlayerLoading(false)}
                  onStart={() => setIsPlayerLoading(false)}
                  onBuffer={() => setIsPlayerLoading(true)}
                  style={{ minHeight: !errors?.videoLink ? 300 : 0 }}
                />
              )}
            </Grid>
          ) : null}

          <Grid item xs>
            <TextField
              fullWidth
              multiline
              minRows={3}
              type="text"
              color="primary"
              placeholder="Description de la fiche"
              value={factSheet?.description || ''}
              helperText={errors?.description}
              error={!!errors?.description}
              label="Description de la fiche"
              onChange={(e) => updateFactSheet("description")(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
