import { useCallback, useEffect, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { FieldErrors } from "../../../Utils/yup.utils";
import APIAxios, { APIRoutes } from "../../../API/api.axios";
import { NewHeader } from "../../../Components/Molecules/new-title.component";
import { Popup } from "../../../Theme";
import Close from "../../../Assets/cross_close.svg";
import {
  defaultValues,
  factSheetFirstStepValidation,
  factSheetSecondStepValidation,
} from "./fact-sheet-creation.validation";
import {
  CreateFactSheetDto,
  GetFactSheetByIdDto,
  GetFactSheetsListData,
} from "../../../Types/fact-sheets.types";
import { FactSheetStepper } from "./fact-sheet.stepper";

interface CreateFactSheetProps {
  handleUpdate?: (factSheet: GetFactSheetByIdDto) => void;
  handleClose?: () => void;
}

export const CreateFactSheetPopup = (props: CreateFactSheetProps) => {
  const { handleClose } = props;
  const { factSheetId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [factSheet, setFactSheet] = useState<CreateFactSheetDto>(
    {} as CreateFactSheetDto
  );
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [errors, setErrors] = useState<FieldErrors>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getFactSheetById = useCallback(async () => {
    if (factSheetId) {
      try {
        const res = await APIAxios({
          ...APIRoutes.GETFactSheetById(factSheetId),
        });
        if (res.data) {
          setFactSheet(res.data);
        }
      } catch (err) {}
    } else {
      setFactSheet(defaultValues);
    }
  }, [factSheetId]);

  useEffect(() => {
    getFactSheetById();
  }, [getFactSheetById]);

  const updateFactSheet =
    (name: string) => (value: string | number | string[] | boolean) =>
      setFactSheet({ ...(factSheet || {}), [name]: value });

  const onSubmit = async () => {
    if (factSheet) {
      const factSheetFirstStepErrors = factSheetFirstStepValidation(factSheet);
      const factSheetSecondStepErrors =
        factSheetSecondStepValidation(factSheet);
      const factSheetErrors = {
        ...factSheetFirstStepErrors,
        ...factSheetSecondStepErrors,
      };

      if (!Object.keys(factSheetErrors).length) {
        setIsSubmitting(true);

        try {
          if (factSheetId) {
            const res = await APIAxios({
              ...APIRoutes.PATCHFactSheet(factSheetId),
              data: factSheet,
            });
            const obj = res.data as GetFactSheetsListData;

            if (obj) {
              enqueueSnackbar(
                `La fiche ${factSheet?.title} a bien été modifiée`,
                { variant: "success" }
              );
            }

            navigate("/admin/fact-sheets", { state: { refresh: true } });

            window.location.reload();
          } else {
            console.log("factSheet to create :", factSheet?.actionTypes);

            const res = await APIAxios({
              ...APIRoutes.POSTFactSheet(),
              data: factSheet,
            });
            const obj = res.data as GetFactSheetsListData;

            if (obj) {
              enqueueSnackbar(`La fiche ${factSheet?.title} a bien été créée`, {
                variant: "success",
              });
            }

            navigate("/admin/fact-sheets", { state: { refresh: true } });
          }
        } catch (err: any) {
          console.log("err", err);

          enqueueSnackbar(
            "Une erreur est survenue. Veuillez réessayer plus tard.",
            { variant: "error" }
          );
        } finally {
          setIsSubmitting(false);
        }
      } else {
        setErrors(factSheetErrors);

        if (stepNumber !== 0 && factSheetFirstStepErrors) {
          setStepNumber(0);
        }

        enqueueSnackbar("Veuillez corriger les erreurs dans le formulaire", {
          variant: "error",
        });
      }
    }
  };

  return (
    <Popup open={true} maxWidth="md" fullWidth>
      <Stack padding={4} gap={3}>
        <Stack justifyContent="space-between" direction="row" alignItems="top">
          <NewHeader
            title={`${
              factSheetId ? "Modifier" : "Créer"
            } une fiche d'information`}
          />

          <IconButton
            onClick={() => (handleClose ? handleClose() : navigate(-1))}
          >
            <img src={Close} alt="Croix" />
          </IconButton>
        </Stack>

        <FactSheetStepper
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          factSheet={factSheet}
          factSheetId={factSheetId}
          updateFactSheet={updateFactSheet}
          errors={errors}
          setErrors={setErrors}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </Popup>
  );
};
