import axios, { AxiosRequestConfig } from "axios";

const APIAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
});

APIAxios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');

    try {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  }, 
  (err) => Promise.reject(err)
);

export const APIRoutes = {

  /* --------- LOGIN --------- */

  POSTLogin: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/login" }),
  POSTLostPassword: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/lost-password" }),
  POSTPassword: (): AxiosRequestConfig => ({ method: "PATCH", url: "/auth/password" }),
  POSTResetPassword: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/generate-new-lost-password" }),
  DELETEAccount : (): AxiosRequestConfig => ({ method: "DELETE", url: "/auth" }),

  /* ----- COMMON PROFILE ----- */

  GETMe: (): AxiosRequestConfig => ({ method: "GET", url: "/auth/me" }),

  /* ------- ORGANISMS ------- */

  POSTSignInOrganism: (): AxiosRequestConfig => ({ method: "POST", url: "/organisms/sign-in"}),
  GETProfileOrganism: (): AxiosRequestConfig => ({ method: "GET", url: "/organisms/me"}),
  PATCHProfileOrganism: (): AxiosRequestConfig => ({ method: "PATCH", url: "/organisms"}),

  /* ----- BENEFICIARIES ----- */

  GETBeneficiaries: (page: number, q?: string, consultantId?: string, organismId?: string): AxiosRequestConfig => ({ method: "GET", url: "/beneficiaries", params: {
    q: q,
    page: page,
    consultantId: consultantId,
    organismId: organismId,
  } }),
  GETIsSuspended: (beneficiaryId: string): AxiosRequestConfig => ({method: "GET", url:`/beneficiaries/${beneficiaryId}/isSuspended`}),
  PATCHBeneficiaries: (): AxiosRequestConfig => ({ method: "PATCH", url: "/beneficiaries" }),
  PATCHBeneficiaryHelp: (beneficiaryId: string): AxiosRequestConfig => ({ method: "PATCH", url: `/beneficiaries/${beneficiaryId}/help` }),
  POSTSignInBeneficiaries: (): AxiosRequestConfig => ({ method: "POST", url: "/beneficiaries/sign-in" }),
  GETProfileBeneficiary: (): AxiosRequestConfig => ({ method: "GET", url: "/beneficiaries/me" }),
  POSTBeneficiaryAction: (beneficiaryId: string): AxiosRequestConfig => ({ method: "POST", url: `/beneficiaries/${beneficiaryId}/action`}),
  GETBenefDashboard: (): AxiosRequestConfig => ({ method: "GET", url: "/beneficiaries/dashboard" }),
  GETBeneficiary: (userId: string): AxiosRequestConfig => ({ method: "GET", url: `/beneficiaries/${userId}`}),
  PATCHSuspendBenef: (benefId: string): AxiosRequestConfig => ({ method: "PATCH", url: `/beneficiaries/${benefId}/suspend`}),

  /* -------- ACTIONS -------- */

  GETAction: (actionId: string): AxiosRequestConfig => ({ method: "GET", url: `/actions/${actionId}`}),
  PATCHAction: (actionId: string): AxiosRequestConfig => ({ method: "PATCH", url: `/actions/${actionId}`}),
  GETActions: (page: number, q?: string, status?: string[], beneficiaryId?: string, consultantId?: string, organismId?: string, dateMin?: string, dateMax?: string): AxiosRequestConfig => ({
    method: "GET", 
    url: "/actions",
    params: {
      q: q,
      status: status,
      page: page,
      beneficiaryId: beneficiaryId,
      dateMin: dateMin,
      dateMax: dateMax,
      actionOwnerId: consultantId,
      organismId: organismId
    } 
  }),
  GETActionsCSV: (q?: string, status?: string[], beneficiaryId?: string, organismId?: string, dateMin?: string, dateMax?: string): AxiosRequestConfig => ({
    method: "GET", 
    url: "/actions/export",
    params: {
      q: q,
      status: status,
      beneficiaryId: beneficiaryId,
      dateMin: dateMin,
      dateMax: dateMax
    } 
  }),

  /* ------- CONSULTANTS ------ */

  GETConsultants: (page: number, search?: string, organismId?: string, take?: number, beneficiaryId?: string): AxiosRequestConfig => ({ method: "GET", url: "/consultants", 
    params: {
      page: page,
      q: search,
      organismId: organismId,
      take: 25,
      beneficiaryId: beneficiaryId
    }}),
  POSTSignInConsultant: (): AxiosRequestConfig => ({ method: "POST", url: "/consultants/sign-in"}),
  GETProfileConsultant: (): AxiosRequestConfig => ({ method: "POST", url: "/consultants/me" }),
  PATCHConsultants: (): AxiosRequestConfig => ({ method: "PATCH", url: `/consultants` }),
  GETConsultantDashboard: (): AxiosRequestConfig => ({ method: "GET", url: "/consultants/dashboard" }),
  GETConsultantById: (consultantId: string): AxiosRequestConfig => ({ method: 'GET', url: `/consultants/${consultantId}`}),
  PATCHConsultantById: (consultantId: string): AxiosRequestConfig => ({ method: 'PATCH', url: `/consultants/${consultantId}`}),
  POSTStartHelpingBeneficiary: (beneficiaryId: string): AxiosRequestConfig => ({ method: 'POST', url: `/consultants/startHelping/${beneficiaryId}`}),
  GETBeneficiariesInvitations: (consultantId: string): AxiosRequestConfig => ({ method: 'GET', url: `/consultants/${consultantId}/invitations`}),

  /* ------- ORGANISMS ------- */

  GETOrganisms: (page: number, q?: string, take?: number): AxiosRequestConfig => ({ method: "GET", url: '/organisms',
  params: {
    page: page,
    take: take || 25,
    q: q
  }}),
  PATCHOganism: (): AxiosRequestConfig => ({ method: 'PATCH', url: '/organisms'}),
  PATCHOrganismById: (organismId: string, data: FormData): AxiosRequestConfig => ({ 
    method: 'PATCH', 
    url: `/organisms/${organismId}`,
    data: data,
    headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
  }),
  GETOrganism: (organismId: string):  AxiosRequestConfig => ({ method: 'GET', url: `/organisms/${organismId}`}),

  /* ----- AUTHORIZATIONS ----- */

  POSTAuthorizations: (): AxiosRequestConfig => ({ method: "POST", url: "/authorizations" }),
  GETAuthorizations: (): AxiosRequestConfig => ({ method: "GET", url: "/authorizations" }),
  POSTInvitation: (authId: string, result: "accept" | "decline"): AxiosRequestConfig => ({ method: "POST", url: `/authorizations/${authId}/${result}` }),
  POSTVerifySignupToken: (token: string): AxiosRequestConfig => ({ method: 'POST', url: `/authorizations/${token}`}),

  /* --------- ADMIN --------- */

  POSTCreateConsultant: (): AxiosRequestConfig => ({ method: 'POST', url: '/consultants/sign-in/admin' }),
  POSTCreateOrganism: (data: FormData): AxiosRequestConfig => ({ 
    method: 'POST', 
    url: '/organisms/sign-in/admin', 
    data: data,
    headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
  }),

  /* --------- NOTIFICATIONS --------- */

  GETBeneficiaryNotifications: (): AxiosRequestConfig => ({ method: 'GET', url: `/notifications/beneficiaries` }),
  GETConsultantNotifications: (): AxiosRequestConfig => ({ method: 'GET', url: `/notifications/consultants` }),
  PATCHReadBeneficiaryNotification: (notificationId: string): AxiosRequestConfig => ({ method: 'PATCH', url: `/notifications/beneficiaries/${notificationId}` }),
  PATCHReadBeneficiaryNotifications: (): AxiosRequestConfig => ({ method: 'PATCH', url: '/notifications/beneficiaries' }),
  PATCHReadConsultantNotification: (notificationId: string): AxiosRequestConfig => ({ method: 'PATCH', url: `/notifications/consultants/${notificationId}` }),
  PATCHReadConsultantNotifications: (): AxiosRequestConfig => ({ method: 'PATCH', url: '/notifications/consultants' }),
  PUTReadBeneficiaryNotifications: (): AxiosRequestConfig => ({ method: 'PUT', url: '/notifications/beneficiaries/readall' }),
  PUTReadConsultantNotifications: (): AxiosRequestConfig => ({ method: 'PUT', url: '/notifications/consultants/readall' }),

  /* --------- FACT SHEETS --------- */

  GETFactSheets: (page?: number, q?: string, take?: number, params?: { actionTypes: string[] }): AxiosRequestConfig => ({ method: 'GET', url: '/fact-sheets', params: {
    page: page,
    q,
    take: take || 25,
    params
  }}),
  GETFactSheetById: (factSheetId: string): AxiosRequestConfig => ({ method: 'GET', url: `/fact-sheets/${factSheetId}` }),
  GETFactSheetIdByActionType: (actionType: string): AxiosRequestConfig => ({ method: 'GET', url: `/fact-sheets/action-type/${actionType}` }),
  POSTFactSheet: (): AxiosRequestConfig => ({ method: 'POST', url: '/fact-sheets'}),
  POSTFactSheetContact: (): AxiosRequestConfig => ({ method: 'POST', url: `/fact-sheets/contact` }),
  PATCHFactSheet: (factSheetId: string): AxiosRequestConfig => ({ method: 'PATCH', url: `/fact-sheets/${factSheetId}` }),
  DELETEFactSheet: (factSheetId: string): AxiosRequestConfig => ({ method: 'DELETE', url: `/fact-sheets/${factSheetId}` }),
};

export default APIAxios;
