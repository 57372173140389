import { useLocation } from 'react-router-dom';
import * as Components from './Components';
import { ResponsiveContainer, PaperContainer } from '../../Components';
import { useStateMachine } from 'little-state-machine';

export const ApplicationTerms = () => {
  const location = useLocation();
  const {
    state: {
      user: { consultant, beneficiary }
    }
  } = useStateMachine();
  return (
    <ResponsiveContainer>
      <PaperContainer>
        {location.pathname.includes('terms-of-service') ? (
          <Components.TermsOfService />
        ) : (
          <Components.PrivacyPolicy userRole={consultant ? 'consultant' : beneficiary ? 'beneficiary' : 'organism'}/>
        )}
      </PaperContainer>
    </ResponsiveContainer>
  );
};
