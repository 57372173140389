import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import { PasswordTextField } from '../../../Theme';
import { TAccountCreateData } from '../../../Types';
import { useReauth } from '../../../Hooks/Auth';
import { ButtonGroup } from '@mui/material';
import { ReauthUser } from './reauth.organism';

import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { useSnackbar } from 'notistack';

const changePasswordValidation = Yup.object().shape({
  email: Yup.string().required('Ce champ est requis').email("Le format de l'e-mail est incorrecte"),
  password: Yup.string().required('Ce champ est requis'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
});

export const ChangePassword = () => {

  const [isModifying, setModifying] = useState<boolean>(false);
  const { reauthNeeded } = useReauth();
  const [newPassword, setNewPassword] = useState<string>('');
  const { state: { user } } = useStateMachine();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(changePasswordValidation),
    mode: 'onChange',
  });

  const handleChangePassword = async (data: Partial<TAccountCreateData>) => {
    try {
      const res = await APIAxios({...APIRoutes.POSTPassword(), data: { password: data.password}});
      if (res.data) {
        setModifying(false);
        enqueueSnackbar('Le mot de passe a bien été modifié', { variant: "success" })
      }
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
      setModifying(true);
    }
  };

  return (
    <>
      <Grid item container justifyContent="space-between" alignItems="center" flexWrap="nowrap" marginBottom={2}>
        <Grid item xs={4}>
          <Typography variant="h3">Identifiants</Typography>
        </Grid>
        <Grid item xs={8} container gap={2} justifyContent="flex-end">
          <ButtonGroup disabled={reauthNeeded}>
            {isModifying && (
              <Button variant="contained" onClick={handleSubmit(handleChangePassword)}>
                Enregistrer
              </Button>
            )}
            <Button
              variant="text"
              color={isModifying ? 'secondary' : 'primary'}
              onClick={() => setModifying(!isModifying)}
            >
              {!isModifying ? 'Modifier' : 'Annuler'}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      {!reauthNeeded && (
        <Grid item xs={12} container direction="column" gap={3}>
          <Grid item xs={12} container direction="column" gap={2}>
            <Controller
              name="email"
              control={control}
              defaultValue={user?.beneficiary?.email ?? user.consultant?.email ?? user.organism?.email ?? user.admin?.email}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled
                  required
                  autoFocus
                  type="email"
                  color="primary"
                  placeholder="Mon adresse e-mail"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            />
            <PasswordTextField
              {...register('password')}
              disabled={!isModifying}
              value={isModifying ? newPassword : 'xxxxxxxxxxxx'}
              onChange={(e) => setNewPassword(e?.target?.value)}
              errorText={errors.password ? errors.password.message : ''}
              error={errors.password ? true : false}
            />
            {isModifying && (
              <Controller
                name="passwordConfirmation"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <PasswordTextField
                    {...field}
                    errorText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
                    error={errors.passwordConfirmation ? true : false}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      )}
      {reauthNeeded && <ReauthUser />}
    </>
  );
};
