import { Fragment } from 'react';
import { codeVerificationValidation } from './invited.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MailOutlined from '@mui/icons-material/MailOutlined';
import { TAccountCreateData } from '../../../../Types';
import { useStateMachine } from 'little-state-machine';
import { updateLoginState, resetLoginState } from '../../../../Stores';
import { IAccountCreateFormsProps } from '../interfaces.form';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    margin: theme.spacing(1, 0) + ' !important',
  },
}));

export const CodeVerificationForm = ({ setCurrentStep }: IAccountCreateFormsProps) => {
  const classes = useStyles();
  const { state } = useStateMachine({ updateLoginState, resetLoginState });
  const { register, formState, getValues } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(codeVerificationValidation),
    mode: 'onChange',
  });

  const { errors } = formState;

  const handleNextStep = async () => {
    const [email] = getValues(['email']);
    if (!email) return;
  };

  return (
    <Fragment>
      <TextField
        {...register('email')}
        defaultValue={state.loginSession.createAccountValues.email || ''}
        autoFocus={!state?.loginSession?.createAccountValues?.email?.length}
        error={errors.email ? true : false}
        required
        type="email"
        color="primary"
        placeholder="Email"
        helperText={errors.email ? errors.email.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      {/* <TextField
        {...register('invitationCode')}
        defaultValue={''}
        error={errors.invitationCode ? true : false}
        autoFocus={!!state?.loginSession?.createAccountValues?.email?.length}
        required
        type="text"
        color="primary"
        placeholder="Code de vérification"
        helperText={errors.invitationCode ? errors.invitationCode.message : 'Vous avez reçu ce code par email'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField> */}
      <Button
        className={classes.actionButton}
        // disabled={!isValid}
        variant="contained"
        color="primary"
        onClick={handleNextStep}
      >
        Vérifier
      </Button>
    </Fragment>
  );
};
