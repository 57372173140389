/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useEffect, useState } from "react";
import { TContainer, TUserRole } from "../Types";
import { ActionStatus, GetActionListDto } from "../Types/actions.type";
import APIAxios, { APIRoutes } from "../API/api.axios";
import { useStateMachine } from "little-state-machine";
import { updateUserActions } from "../Stores";
import axios from "axios";

interface IDateRange {
  from?: Date;
  to?: Date;
  isValid: boolean;
}
interface IActionsContextProps {
  actions: GetActionListDto | null;
  singleBenefActions: GetActionListDto | null;
  // count: number;
  // statusCounts: {
  //   TODO: number;
  //   PENDING: number;
  //   DONE: number;
  //   ABANDONED: number;
  //   REFUSED: number;
  // };
  statusFilters: ActionStatus[];
  typeOrNameFilter: string | null;
  dateFilter: IDateRange | null;
  setPage: (page: number) => void;
  setBenefId: (uid?: string) => void;
  filterByTypeOrName: (value: string) => void;
  filterByStatus: (status: ActionStatus) => void;
  filterByDate: (dateRange: IDateRange) => void;
  beneficiaryId?: string;
  handleUpdate: () => void;
}

export const ActionsContext = createContext<IActionsContextProps>({
  actions: null,
  // count: 0,
  // statusCounts: {
  //   TODO: 0,
  //   PENDING: 0,
  //   DONE: 0,
  //   ABANDONED: 0,
  //   REFUSED: 0
  // },
  setPage: () => {},
  setBenefId: () => {},
  typeOrNameFilter: null,
  dateFilter: null,
  filterByDate: () => {},
  filterByTypeOrName: () => {},
  statusFilters: [],
  filterByStatus: () => {},
  beneficiaryId: undefined,
  handleUpdate: () => {},
  singleBenefActions: null,
});

interface IActionsProviderProps extends TContainer {
  uid: string;
  userRole: TUserRole;
}

let source = axios.CancelToken.source();

export const ActionsProvider = ({
  children,
  uid,
  userRole,
}: IActionsProviderProps) => {
  const [actions, setActions] = useState<GetActionListDto | null>();
  const [singleBenefActions, setSingleBenefActions] =
    useState<GetActionListDto | null>();
  const [statusFilters, setStatusFilters] = useState<ActionStatus[]>([]);
  const [typeOrNameFilter, setTypeOrNameFilter] = useState<string | null>(null);
  const [benefId, setBenefId] = useState<string | undefined>(uid);
  const [dateFilter, setDateFilter] = useState<IDateRange | null>(null);
  const [page, setPage] = useState<number>(1);

  const { actions: storeActions } = useStateMachine({ updateUserActions });  

  const fetchActions = useCallback(async () => {
    try {
      const singleBenefId = benefId || uid || undefined;
      source.cancel();
      source = axios.CancelToken.source();
      const res = await APIAxios({
        ...APIRoutes.GETActions(
          page,
          typeOrNameFilter || undefined,
          statusFilters || undefined,
          benefId || uid || "",
          "",
          "",
          dateFilter?.from?.toISOString(),
          dateFilter?.to?.toISOString()
        ),
        cancelToken: source.token,
      });
      setActions(res.data);
      if (singleBenefId) {
        setSingleBenefActions(res.data);
      } else {
        setActions(res.data);
      }
      storeActions.updateUserActions(res.data.data);
    } catch (err) {}
  }, [
    page,
    typeOrNameFilter,
    statusFilters,
    benefId,
    dateFilter?.from,
    dateFilter?.to,
    storeActions,
    uid,
  ]);

  useEffect(() => {
    fetchActions();
    window.addEventListener("reload-actions", fetchActions);
    return () => {
      window.removeEventListener("reload-actions", fetchActions);
    };
  }, [fetchActions]);

  useEffect(() => {
    if (uid) {
      setBenefId(uid);
    }
  }, [uid]);

  const _filterByStatus = useCallback(
    (status: ActionStatus) => {
      if (statusFilters.includes(status)) {
        setStatusFilters([...statusFilters.filter((s) => s !== status)]);
      } else {
        setStatusFilters([...statusFilters, status]);
      }
      fetchActions();
    },
    [statusFilters, fetchActions]
  );

  const handleUpdate = () => {
    fetchActions();
  };

  const contextValue = {
    actions: actions as GetActionListDto,
    statusFilters,
    setPage: (page: number) => setPage(page + 1),
    typeOrNameFilter: typeOrNameFilter,
    dateFilter: dateFilter,
    setBenefId: (uid?: string) => setBenefId(uid),
    filterByDate: useCallback(
      (dateRange: IDateRange) => setDateFilter(dateRange),
      []
    ),
    filterByStatus: (status: ActionStatus) => _filterByStatus(status),
    filterByTypeOrName: useCallback((value: string) => {
      setTypeOrNameFilter(value);
    }, []),
    beneficiaryId: benefId,
    singleBenefActions: singleBenefActions as GetActionListDto,
    handleUpdate: handleUpdate,
  };

  return (
    <ActionsContext.Provider value={contextValue}>
      {children}
    </ActionsContext.Provider>
  );
};
