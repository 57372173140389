import { useCallback, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import { GetBeneficiaryDto, StatusBoe, TypeWorkArrest } from '../../../../Types';
import { actualSituation } from './forms.validation';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { updateUserState } from '../../../../Stores';

export const ActualSituationForm = () => {
  const {
    state: {
      user: { beneficiary },
    },
    actions
  } = useStateMachine({ updateUserState });
  const { formState, control, watch, handleSubmit, reset } = useForm<Partial<GetBeneficiaryDto>>({
    resolver: yupResolver(actualSituation),
    mode: 'onChange',
  });
  const [isModifying, setModifying] = useState<boolean>(false);
  const isBOE = watch('isBOE', beneficiary?.isBOE);
  const isWorkArrest = watch('isWorkArrest', beneficiary?.isWorkArrest);

  const onSubmit = useCallback(
    async (data: Partial<GetBeneficiaryDto>) => {
      if (data.isWorkArrest === false) {
        data.dateWorkArrest = undefined;
        data.typeWorkArrest = undefined;
      }
      if (data.isBOE === false) {
        data.statusBOE = undefined;
      }
      try {
        const res = await APIAxios({...APIRoutes.PATCHBeneficiaries(), data: {
           ...data,
           typeWorkArrest: data.typeWorkArrest === '' ? undefined : data.typeWorkArrest,
           statusBOE: data.statusBOE === '' ? undefined : data.statusBOE
        }})
        actions.updateUserState({beneficiary: res.data});
        setModifying(false);
      } catch (err) {
      }
      setModifying(false);
    },
    [actions],
  );

  const convert2bool = (s: string) => {
    if (s.toLowerCase() === 'true') {
      return true;
    } else if (s.toLowerCase() === 'false') {
      return false;
    }
    console.error('unknown casted type');
    return false;
  };

  const handleClick = () => {
    if (isModifying) {
      reset(beneficiary);
    }
    setModifying(!isModifying);
  };

  return (
    <Grid item container direction="row" gap={4}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Situation actuelle</Typography>
        <Button variant="text" color={isModifying ? 'secondary' : 'primary'} onClick={handleClick}>
          {isModifying ? 'Annuler' : 'Modifier'}
        </Button>
      </Grid>
      <Grid container gap={1}>
        <Typography fontWeight="medium" variant="body1">
          Êtes-vous Bénéficiaire de l&apos;Obligation d&apos;emploi (BOE) ?
        </Typography>
        <Grid container gap={1}>
          <Controller
            name="isBOE"
            control={control}
            defaultValue={beneficiary?.isBOE || false}
            render={({ field }) => (
              <FormControl fullWidth component="fieldset" error={!!formState.errors.isBOE} disabled={!isModifying}>
                <RadioGroup
                  {...field}
                  row
                  value={field.value}
                  onChange={(e) => field.onChange(convert2bool(e.target.value))}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Oui" />
                  <FormControlLabel value={false} control={<Radio />} label="Non" />
                </RadioGroup>
                {!!formState.errors.isBOE && <FormHelperText>{formState.errors.isBOE.message}</FormHelperText>}
              </FormControl>
            )}
          />
          {isBOE && (
            <>
              <Typography fontWeight="medium" variant="body1">
                Précisez votre situation
              </Typography>
              <Controller
                name="statusBOE"
                defaultValue={beneficiary?.statusBOE || ''}
                control={control}
                shouldUnregister={true}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isModifying}
                    fullWidth
                    error={!!formState.errors.statusBOE}
                    helperText={formState.errors.statusBOE ? formState.errors.statusBOE.message : ''}
                    select
                    type="text"
                    color="primary"
                    placeholder="Sélectionner une situation"
                    label="Sélectionner une situation"
                  >
                    {StatusBoe.statuses.map((it) => (
                      <MenuItem value={it} key={it}>
                        {StatusBoe.label(it)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Typography fontWeight="medium" variant="body1" margin={0}>
          Êtes-vous en arrêt de travail ?
        </Typography>
        <Grid container gap={2}>
          <Controller
            name="isWorkArrest"
            rules={{ required: true }}
            defaultValue={beneficiary?.isWorkArrest}
            control={control}
            render={({ field }) => (
              <FormControl component="fieldset" error={!!formState.errors.isWorkArrest} disabled={!isModifying}>
                <RadioGroup
                  {...field}
                  row
                  value={field.value}
                  onChange={(e) => field.onChange(convert2bool(e.target.value))}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Oui" />
                  <FormControlLabel value={false} control={<Radio />} label="Non" />
                </RadioGroup>
                {!!formState.errors.isWorkArrest && (
                  <FormHelperText>{formState.errors.isWorkArrest.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          {isWorkArrest && (
            <>
              <Controller
                name="dateWorkArrest"
                control={control}
                defaultValue={beneficiary?.dateWorkArrest && new Date(beneficiary.dateWorkArrest).toISOString().split('T')[0]}
                shouldUnregister={true}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isModifying}
                    fullWidth
                    error={!!formState.errors.dateWorkArrest}
                    helperText={formState.errors.dateWorkArrest ? formState.errors.dateWorkArrest.message : ''}
                    required
                    type="date"
                    color="primary"
                    placeholder="Date d'arret de travail"
                    label="Date d'arret de travail"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <Controller
                name="typeWorkArrest"
                defaultValue={beneficiary?.typeWorkArrest || ''}
                control={control}
                shouldUnregister={true}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isModifying}
                    fullWidth
                    error={!!formState.errors.typeWorkArrest}
                    helperText={formState.errors.typeWorkArrest ? formState.errors.typeWorkArrest.message : ''}
                    select
                    defaultValue=""
                    type="text"
                    color="primary"
                    placeholder="Type d'arret de travail"
                    label="Type d'arret de travail"
                  >
                    {TypeWorkArrest.typeWorkArrests.map((it) => (
                      <MenuItem value={it} key={it}>
                        {TypeWorkArrest.label(it)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Button style={{ flexGrow: 1 }} variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isModifying}>
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  );
};
